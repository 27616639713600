/* eslint-disable no-lone-blocks */
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  ONE_USD_TO_CENTS,
  OrderStatus,
  SortDirection,
  SortFieldsEnumForTransactionHistoryQuery,
  UnitDisplayNames,
  UnitDisplaySymbol,
  UserType,
} from '../../utils/types'
import { getuserSellOrder } from '../../api'
import {
  SortableTableHeader,
  TableTopFilters,
} from '../../component/misc/smallComponents'
import { UnitType } from '../../utils/types'
import {
  formatDate,
  formatNumberWithCommas,
  formatNumberWithCommasUptoTwoDecimalWithCeil,
} from '../../utils/utilities'
const SellOrderView = () => {
  const [state, setState] = useState<any>({
    sortDirection: SortDirection.desc,
    isExportDownloadLoading: false,
    dataList: [],
    currentPage: 1,
    pageSize: 50,
    searchTerm: '',
    searchType: 'name',
    unitType: '',
    numberOfUnits: '',
    orderStatus: '',
    sortBy: SortFieldsEnumForTransactionHistoryQuery.UpdatedAt,
    fromDate: '',
    toDate: '',
    userType: '',
  })

  const getData = async () => {
    const {
      currentPage,
      pageSize,
      searchType,
      searchTerm,
      fromDate,
      toDate,
      userType,
      unitType,
      orderStatus,
      sortBy,
      sortDirection,
    } = state
    getuserSellOrder(
      currentPage,
      pageSize,
      searchType,
      searchTerm,
      fromDate,
      toDate,
      unitType,
      userType,
      orderStatus,
      sortBy,
      sortDirection,
    )
      .then((res) => {
        setState((prevState: any) => ({
          ...prevState,
          dataList: res.data.orders,
        }))
      })
      .catch((err) => {
        console.log(err)
        toast.error(err.response.data.message)
      })
  }
  useEffect(() => {
    getData()
  }, [
    state.currentPage,
    state.fromDate,
    state.toDate,
    state.unitType,
    state.userType,
    state.sortBy,
    state.sortDirection,
    state.orderStatus,
  ])

  const handleExportOrders = async () => {
    setState((prevState: any) => ({
      ...prevState,
      isExportDownloadLoading: true,
    }))
    try {
      const {
        currentPage,
        pageSize,
        searchType,
        searchTerm,
        fromDate,
        toDate,
        unitType,
        userType,
        orderStatus,
        sortBy,
        sortDirection,
      } = state
      const response = await getuserSellOrder(
        currentPage,
        pageSize,
        searchType,
        searchTerm,
        fromDate,
        toDate,
        unitType,
        userType,
        sortBy,
        orderStatus,
        sortDirection,
        true,
      )

      const blob = new Blob([response.data], { type: 'text/csv' })

      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'orders.csv') // Set the file name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link) // Clean up

      setState((prevState: any) => ({
        ...prevState,
        isExportDownloadLoading: false,
      }))
    } catch (error: any) {
      toast.error(error.response?.data?.message || 'Failed to download CSV')
      setState((prevState: any) => ({
        ...prevState,
        isExportDownloadLoading: false,
      }))
    }
  }
  const resetAllFiltersAndSort = () => {
    setState({
      sortDirection: SortDirection.desc,
      dataList: [],
      searchTerm: '',
      searchType: 'name',
      unitType: '',
      numberOfUnits: '',
      orderStatus: '',
      fromDate: '',
      toDate: '',
      sortBy: SortFieldsEnumForTransactionHistoryQuery.CreatedAt,
    })
    getData()
  }

  const handleSortTableHead = (
    property: SortFieldsEnumForTransactionHistoryQuery,
  ) => {
    setState((prevState: any) => {
      const newSortDirection =
        prevState.sortDirection === 'asc' ? 'desc' : 'asc'
      return {
        ...prevState,
        sortBy: property,
        sortDirection: newSortDirection,
      }
    })
  }
  console.log(state)
  return (
    <body className="fixed-nav sticky-footer dash-img-bg" id="page-top">
      <div className="content-wrapper ">
        <div className="container-fluid">
          <ol
            className="breadcrumb"
            style={{ paddingTop: '2rem', paddingBottom: '0.6rem' }}
          >
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">Sell Orders in process</li>
          </ol>

          <div className="card mb-3">
            <div className="card-body">
              <div className="table-responsive">
                <TableTopFilters
                  state={state}
                  setState={setState}
                  getData={getData}
                  showDateRange={true}
                  handleExportOrders={handleExportOrders}
                />
                <table
                  className="table table-bordered text-nowrap text-center"
                  id="dataTable"
                  width="100%"
                  cellSpacing=""
                >
                  <thead className="">
                    <tr>
                      <th>S.No.</th>
                      <th>Name </th>
                      <th>Email</th>
                      <th>
                        <span className="d-block">Currency</span>

                        <select
                          className="w-100"
                          value={state.unitType}
                          onChange={(e) =>
                            setState({
                              ...state,
                              unitType: e.target.value,
                            })
                          }
                        >
                          <option value="">All</option>
                          {Object.values(UnitType).map((unit) => (
                            <option key={unit} value={unit}>
                              {UnitDisplayNames[unit]}
                            </option>
                          ))}
                        </select>
                      </th>

                      <SortableTableHeader
                        label="No.of Units"
                        property="currencyUnit.numberOfUnits"
                        sortBy={state.sortBy}
                        sortDirection={state.sortDirection}
                        handleSortTableHead={handleSortTableHead}
                      />
                      <th>Units Unsold</th>
                      <SortableTableHeader
                        label="Date"
                        property={
                          SortFieldsEnumForTransactionHistoryQuery.UpdatedAt
                        }
                        sortBy={state.sortBy}
                        sortDirection={state.sortDirection}
                        handleSortTableHead={handleSortTableHead}
                      />
                      <th>
                        <span className="d-block">Status</span>
                        <select
                          className="w-100"
                          onChange={(e) =>
                            setState({ ...state, orderStatus: e.target.value })
                          }
                        >
                          <option value="">All</option>
                          {Object.values(OrderStatus)
                            .filter(
                              (status) =>
                                status === OrderStatus.InProcess ||
                                status === OrderStatus.PartialComplete,
                            )
                            .map((status) => (
                              <option key={status} value={status}>
                                {status}
                              </option>
                            ))}
                        </select>
                      </th>
                      <th>Transaction Fee (in Units)</th>

                      <SortableTableHeader
                        label="Payout Amount"
                        property="paymentDetails.paymentAmount"
                        sortBy={state.sortBy}
                        sortDirection={state.sortDirection}
                        handleSortTableHead={handleSortTableHead}
                      />
                      <th>
                        <span className="d-block">Account Type</span>

                        <select
                          className="w-100"
                          value={state.userType}
                          onChange={(e) =>
                            setState({
                              ...state,
                              userType: e.target.value,
                            })
                          }
                        >
                          <option value="">All</option>
                          <option value={UserType.USER}>USER</option>
                          <option value={UserType.BUSINESS}>BUSINESS</option>
                        </select>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {state?.dataList &&
                      state.dataList.map((item: any, index: number) => {
                        return (
                          <tr key={index} className="text-center">
                            <td>
                              <b>{index + 1}.</b>
                            </td>

                            <td>
                              {item.user?.firstName + ' ' + item.user?.lastName}
                            </td>
                            <td>{item.user?.email}</td>
                            <td>
                              {
                                UnitDisplayNames[
                                  item.currencyUnit?.unitType as UnitType
                                ]
                              }
                            </td>
                            <td>
                              {formatNumberWithCommas(
                                item.currencyUnit?.numberOfUnits,
                              )}
                            </td>
                            <td>
                              {formatNumberWithCommas(
                                item.sell.actualUnitSoldLeft,
                              )}
                            </td>
                            <td> {formatDate(item.updatedAt?.toString())}</td>
                            <td> {item.orderStatus}</td>
                            <td>
                              {
                                item.currencyUnit
                                  ?.sellOrderTransactionFeeInUnits
                              }{' '}
                              {
                                UnitDisplayNames[
                                  item.currencyUnit?.unitType as UnitType
                                ]
                              }
                            </td>
                            <td>
                              {formatNumberWithCommasUptoTwoDecimalWithCeil(
                                item.paymentDetails?.paymentAmount /
                                  ONE_USD_TO_CENTS,
                              )}{' '}
                              {
                                UnitDisplaySymbol[
                                  item.currencyUnit?.unitType as UnitType
                                ]
                              }
                            </td>
                            <td>{item.user?.userType}</td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  )
}

export default SellOrderView
