import React, { useState, useEffect, FormEvent } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import '../../App'
import './login.scss'
import { toast } from 'react-toastify'
import { USER_URI } from '../../utils/utilities'
import { Credentials } from '../../utils/types'
import { getUserOrSuperAdmin, postLogin } from '../../api'

const Login: React.FC = () => {
  const imageUrl = 'images/4858794.jpg' // Path to your image

  const divStyle = {
    backgroundImage: `url(${imageUrl})`,
    // Adjust width and height as needed
    // Add other styles if needed
  }
  const [isDropdownOpen, setDropdownOpen] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [msg, setMsg] = useState('')
  const navigate = useNavigate()
  const [auth, setAuth] = useState<string>('')

  useEffect(() => {
    const getSuperAdmin = () => {
      getUserOrSuperAdmin()
        .then((res) => {
          setAuth(res.data.user)
        })
        .catch((error) => {
          console.log(error)
        })
    }
    getSuperAdmin()
  }, [])

  const handleDropdownToggle = () => {
    setDropdownOpen(!isDropdownOpen)
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    setMsg('')
    const credentials: Credentials = { email, password }
    postLogin(credentials)
      .then((res) => {
        toast.success('Login successfully')
        setMsg(res.data.message)
        setMsg('')
        navigate('/', { replace: true })
        window.location.reload()
      })
      .catch((err: any) => {
        console.log(err)
        setMsg(err.response.data.message)
      })
  }

  return (
    <>
      <div id="page">
        <nav
          className="dash-nav navbar navbar-expand-lg navbar-dark  fixed-top align-items-center justify-content-between"
          id="mainNav"
        >
          <div className="d-flex align-items-center align-items-lg-end">
            <div className="dropdown me-3">
              {auth ? (
                <button
                  className="p-0 btn dropdown-toggle quan-toggle shadow-none"
                  type="button"
                  id="dropdownMenuButton2"
                  data-bs-toggle="dropdown"
                  aria-expanded={isDropdownOpen}
                  onClick={handleDropdownToggle}
                >
                  <i className="ti-menu"></i>
                </button>
              ) : (
                ''
              )}
              <ul
                className={`dropdown-menu quan-menu ${isDropdownOpen ? 'show' : ''}`}
                aria-labelledby="dropdownMenuButton2"
              >
                <li>
                  <Link to="/" className="dropdown-item">
                    Dashboard
                  </Link>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Quantum Mandate
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Buy Land Units
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Buy eAUD Units
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    About Us
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Contact
                  </a>
                </li>
              </ul>
            </div>
            <div className="dropdown p-0 d-block d-lg-none ms-sm-5 ms-4">
              <button
                className="p-0 btn dropdown-toggle quan-toggle"
                type="button"
                id="dropdownMenuButton2"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="ti-user mobile-user"></i>
              </button>
              <ul
                className="dropdown-menu quan-menu"
                aria-labelledby="dropdownMenuButton2"
              >
                <li>
                  <a className="dropdown-item" href="log-in.html">
                    LOG-IN
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="register.html">
                    REGISTER
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    LOG-OUT
                  </a>
                </li>
              </ul>
            </div>
            {auth ? (
              <ul className="navbar-nav align-items-end d-none d-lg-flex">
                <li className="nav-item">
                  <a
                    href="#"
                    className="nav-link ms-3"
                    data-toggle="modal"
                    data-target="#exampleModal"
                  >
                    LOG-OUT
                  </a>
                </li>
              </ul>
            ) : (
              <ul className="navbar-nav align-items-end d-none d-lg-flex ">
                <li className="nav-item ">
                  <Link to="/login" className="nav-link ms-3">
                    LOG-IN
                  </Link>
                </li>
                {/* <li className="nav-item">
                    <Link to='/register' className="nav-link ms-3">
                      REGISTER </Link>
                  </li> */}
              </ul>
            )}
          </div>
          <a
            className="navbar-brand ms-auto p-0 d-none d-lg-block"
            href="index.html"
          >
            <img src="images/quantum-logo.png" alt="" height="36" />
          </a>
          <div className="d-lg-none d-block dash-mobile-logo">
            <a href="index.html">
              <img src="images/quantum-logo.png" alt="" />
            </a>
          </div>
        </nav>

        <main className="">
          <section className="sec-img" style={divStyle}>
            <div
              className="container margin_60"
              style={{ paddingTop: '150px' }}
            >
              <div className="row justify-content-center">
                <div className="col-xl-6 col-lg-6 col-md-8">
                  <div className="login-from-q">
                    <div className="text-center mb-5">
                      <h3>Account Log-In</h3>
                    </div>
                    <form onSubmit={handleSubmit}>
                      <div className="form-group mb-3">
                        <label>Email</label>
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          id="email"
                          onChange={(e) => {
                            setEmail(e.target.value)
                          }}
                        />
                        <i className="icon_mail_alt"></i>
                      </div>
                      <div className="form-group mb-3">
                        <label>Password</label>
                        <div
                          className="hideShowPassword-wrapper"
                          style={{
                            position: 'relative',
                            display: 'block',
                            margin: '0px',
                          }}
                        >
                          <input
                            type="password"
                            className="form-control"
                            name="password"
                            id="password"
                            style={{ margin: '0', paddingRight: '51px' }}
                            onChange={(e) => {
                              setPassword(e.target.value)
                            }}
                          />

                          <button
                            type="button"
                            role="button"
                            aria-label="Show Password"
                            title="Show Password"
                            tabIndex={0}
                            className="my-toggle hideShowPassword-toggle-show"
                            aria-pressed="false"
                            style={{
                              position: 'absolute',
                              right: '0px',
                              top: '50%',
                              marginTop: '-15px',
                              display: 'none',
                            }}
                          >
                            Show
                          </button>
                        </div>
                        <i className="icon_lock_alt"></i>
                      </div>
                      <div className="clearfix add_bottom_30">
                        <div className="float-end mt-1">
                          <strong>
                            {' '}
                            <Link to={`/login/forgot/`}>Forgot Password?</Link>
                          </strong>
                        </div>
                      </div>
                      <h4 style={{ color: 'red !important' }}>{msg}</h4>

                      <button
                        type="submit"
                        className="btn_1 rounded full-width color"
                      >
                        Login Now
                      </button>

                      <div className="text-center add_top_10">
                        New to Quantum 2 ?
                        <strong>
                          {' '}
                          <Link target="_blank" to={`${USER_URI}/register`}>
                            Register here
                          </Link>
                        </strong>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </>
  )
}

export default Login
