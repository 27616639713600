import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import '../../App.css'
import { Link, useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Form, FormGroup, Input, Label } from 'reactstrap'
import * as yup from 'yup'
import { NeweOperationStateType, News, NewsCategoryInterface, NewsStatus } from '../../utils/types'
import { createNews, addImage, revalidateNextJsPages, getAllNewsCategory, putNews } from '../../api'
import QButton from '../../component/DesignSystem/Core/Common/QButton/QButton'
import { FormErrorMessage } from '../../component/FormErrorMessage'
import { toast } from 'react-toastify'
import { createFormDataForAddingNews } from '../../utils/utilities'


// Importing styles

import ReactQuill from 'react-quill'
import { urlBasedOnEnv } from '../../utils/constants'
import { init_revalidate_next_pages, qtScrollIntoView, stringToSlug } from '../../utils/common'
import LoadingBlock from '../../component/misc/smallComponents'
export const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
  'color',
  'clean',
]

export const CustomReactQuill = forwardRef<
  ReactQuill,
  ReactQuill.ReactQuillProps
>((props, ref) => <ReactQuill {...props} ref={ref} />)

const AddNews: React.FC = () => {
  const [selectedNews, setSelectedNews] = useState<News | null>(null)
  const [operationState, setOperationState] = useState<NeweOperationStateType>(NeweOperationStateType.Create) // create
  const [categoryList, setcategoryList] = useState<NewsCategoryInterface[]>([]);
  const schema = yup.object().shape({
    slug: yup.string().required('Web link  is required').label('Web link '),
    metaTitle: yup
      .string()
      .required('Meta Title is required')
      .label('Meta Title'),
    metaDescription: yup
      .string()
      .required('Meta Description is required')
      .label('Meta Description'),
    title: yup.string().required('Title is required').label('Title'),
    newsCategoryId: yup.string().required('Category is required').label('Title'),
    article: yup.string().required('Article is required').label('Article'),
    status: yup
      .string()
      .oneOf(
        [NewsStatus.Saved, NewsStatus.Published],
        "News status must be 'Saved or Published",
      ),
    img: yup
      .mixed()
      .test(
        'fileSize',
        'Please upload an image',
        (value) => !value || value.length > 0,
      )
      .test(
        'fileSize',
        'File size must be less than 1MB',
        (value) =>
          !value || (value.length > 0 && value[0].size <= 1 * 1024 * 1024), // 1MB limit
      )
      .test(
        'fileType',
        'Unsupported file type. Please upload a PDF, JPEG, or PNG.',
        (value) =>
          !value ||
          (value.length > 0 &&
            [
              'application/pdf',
              'image/jpeg',
              'image/jpg',
              'image/png',
            ].includes(value[0].type)),
      ),
  })
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm<News>({
    resolver: yupResolver(schema),
  })
  const title = watch('title')
  useEffect(() => {
    if (title) {
      const slug = stringToSlug(title)
      setValue('slug', slug)
    }
  }, [title, setValue])

  const quillRef = useRef<ReactQuill>(null)

  const getAllCategory = async () => {
    getAllNewsCategory().then((res) => {
      console.log('res:', res)
      //@ts-ignore
      // setData([...(res.data as NewsCategoryInterface[])]) // <--

      setcategoryList(res.data);
      //@ts-ignore
      if (res.data.length > 0) {

        //@ts-ignore
        setValue('newsCategoryId', res.data[0]._id)
      }
    })
  }
  useEffect(() => {
    getAllCategory()
  }, [])
  const [imageLoading, setimageLoading] = useState(false);
  const handleImageUpload = useCallback(() => {
    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.click()

    input.onchange = async () => {
      const file = input.files?.[0]
      if (file) {
        const formData = new FormData()
        formData.append('image', file)

        try {
          setimageLoading(true);
          const response = await addImage(formData)
          //@ts-ignore
          const imageUrl = response.data.fileUrl

          console.log('quill.current:', quillRef)
          // @ts-ignore
          const quill = quillRef.current?.getEditor()
          const range = quill?.getSelection()?.index

          if (range !== undefined && range >= 0) {
            quill?.insertEmbed(range, 'image', imageUrl)
          }
          setimageLoading(false);

        } catch (error) {
          setimageLoading(false);
          console.error('Error uploading image:', error)
          alert('Error uploading image')
        }
      }
    }
  }, [])

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [2, 3, 4, false] }],
          ['bold', 'italic', 'underline', 'blockquote'],
          [{ color: [] }],
          [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' },
          ],
          ['link', 'image', "video"],
          ['clean'],
        ],
        handlers: {
          // image: imageHandler,
          image: handleImageUpload,
        },
      },
      clipboard: {
        matchVisual: true,
      },
    }),
    // [imageHandler],
    [handleImageUpload],
  )

  const [isLoadingSave, setIsLoadingSave] = useState(false)
  const [isLoadingPublish, setIsLoadingPublish] = useState(false)
  const [saveMessage, setSaveMessage] = useState('')

  const [errorMessage, setErrorMessage] = useState<string>('')

  const setLoadingForSaveAndPublish = (status: NewsStatus, state: boolean) => {
    if (status === NewsStatus.Saved) {
      setIsLoadingSave(state)
    } else if (status === NewsStatus.Published) {
      setIsLoadingPublish(state)
    }
  }
  const navigate = useNavigate()
  const saveOrPublishNews = async (
    news: News,
    status: NewsStatus,
    cb?: () => void,
  ) => {
    if (!news) return
    setLoadingForSaveAndPublish(status, true)
    setErrorMessage('')
    news.status = status;


    if (operationState === NeweOperationStateType.Update) {
      news.id = selectedNews!.id
      news.slug = selectedNews!.slug
    }
    const newsWithFormData = createFormDataForAddingNews(news)
    const entriesArray = Array.from(newsWithFormData.entries());
    entriesArray.forEach(([key, value]) => {
      console.log(`${key}: ${value}`);
    });

    if (operationState === NeweOperationStateType.Create) {
      createNews(newsWithFormData)
        .then(async ({ data: { message, news: savedNews } }) => {
          setOperationState(NeweOperationStateType.Update);
          setSelectedNews(savedNews);
          setSaveMessage(message)
          toast.success(message)
          if (typeof cb === 'function') {
            cb()
          } else {
            // navigate('/news/operation/')
          }
          setTimeout(() => {
            setSaveMessage('')
            setLoadingForSaveAndPublish(status, false)
          }, 500)
          if (status === NewsStatus.Published) {
            init_revalidate_next_pages()
          }
        })
        .catch((err) => {
          setOperationState(NeweOperationStateType.Create)
          setLoadingForSaveAndPublish(status, false)
          setErrorMessage(err.response.data.message)
          toast.error(err.response.data.message, {
            position: 'bottom-center',
          })
          console.log('error', err)
        })
    } else if (operationState === NeweOperationStateType.Update) {
      putNews(newsWithFormData).then(async ({ data: { message, news: savedNews } }) => {
        setSelectedNews(savedNews);
        setSaveMessage(message)

        setTimeout(() => {
          setSaveMessage('')
          setLoadingForSaveAndPublish(status, false)
        }, 500)
        if (typeof cb === 'function') {
          cb()
        }
        if (status === NewsStatus.Published) {
          init_revalidate_next_pages()
        }

      })
        .catch((err) => {
          console.log('error', err)
          setLoadingForSaveAndPublish(status, false)
          if (err.response) {
            setErrorMessage(err.response.data.message)
            toast.error(err.response.data.message, { position: 'bottom-center' })
          }
        })
    }

  }

  const addNewArticle = () => {
    reset();
    setOperationState(NeweOperationStateType.Create);
    setSelectedNews(null);

    if (categoryList.length > 0) {
      // @ts-ignore
      setValue('newsCategoryId', categoryList[0]._id)


    }
    qtScrollIntoView("news-create-form");
  }
  return (
    <>
      <body className="fixed-nav sticky-footer dash-img-bg" id="page-top">
        <div className="content-wrapper ">
          <div className="container">
            <ol className="breadcrumb">
              <li
                className="breadcrumb-item"
                style={{
                  paddingTop: '2rem',
                  paddingBottom: '0.6rem',
                }}
              >
                <Link to="/">Dashboard</Link>
              </li>
              <li
                className="breadcrumb-item active"
                style={{
                  paddingTop: '2rem',
                  paddingBottom: '0.6rem',
                }}
              >
                Add News
              </li>
            </ol>
            {saveMessage && <div className="save-message">{saveMessage}</div>}
            <Form
              // className={`row ${!isValid ? "needs-validation" : ""}  ${
              //   isSubmitted ? "was-validated" : ""
              // }`}
              className={`row `}
              id='news-create-form'
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="col-lg-12">
                <div className="box_general padding_bottom add-land-form">
                  <div className="header_box version_2">
                    <h2>
                      <i className="fa fa-plus "></i>Enter News Details
                    </h2>
                  </div>
                  {saveMessage && (
                    <div className="save-message">{saveMessage}</div>
                  )}

                  <div className="row">
                    <div className="col-md-9">
                      <FormGroup>
                        <label>Title</label>
                        <input
                          type="text"
                          className="form-control"
                          {...register('title')}
                        />
                        <FormErrorMessage>
                          {errors.title?.message}
                        </FormErrorMessage>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <label>Image</label>
                        <input
                          type="file"
                          className="form-control"
                          {...register('img')}
                        />
                        <FormErrorMessage>
                          {errors.img?.message}
                        </FormErrorMessage>
                      </FormGroup>
                    </div>
                    <div className="col-md-12">
                      <FormGroup>
                        <label>Web link(Slug)</label>
                        <input className="form-control" {...register('slug')} />
                        <FormErrorMessage>
                          {errors.slug?.message}
                        </FormErrorMessage>
                      </FormGroup>
                    </div>

                    <div className="col-md-12">
                      <FormGroup>
                        <label>Meta Title</label>
                        <textarea
                          className="form-control"
                          {...register('metaTitle')}
                        />
                        <FormErrorMessage>
                          {errors.metaTitle?.message}
                        </FormErrorMessage>
                      </FormGroup>
                    </div>
                    <div className="col-md-12">
                      <FormGroup>
                        <label>Meta Description</label>
                        <textarea
                          className="form-control"
                          {...register('metaDescription')}
                        />
                        <FormErrorMessage>
                          {errors.metaDescription?.message}
                        </FormErrorMessage>
                      </FormGroup>
                    </div>
                    <div className="col-md-12">

                      <div className="form-group mb-3">
                        <label>Category</label>


                        <Controller
                          name="newsCategoryId"
                          control={control}

                          render={({ field }) => (
                            <Input
                              style={{ marginBottom: '1rem' }}
                              type="select"
                              className=''
                              placeholder="Enter Category"
                              value={field.value}
                              onChange={field.onChange}
                            >
                              {
                                categoryList.map(c => <option value={c._id}>
                                  {c.name}
                                </option>)
                              }



                            </Input>
                          )}
                        />
                        {errors.newsCategoryId && (
                          <FormErrorMessage>{errors.newsCategoryId.message}</FormErrorMessage>
                        )}
                      </div>


                    </div>
                    <div className="col-md-12">
                      <FormGroup>
                        <label>Article</label>

                        {/* <textarea
                          className="form-control"
                          {...register('article')}
                        ></textarea> */}
                        <div className="text-editor-wrapper">
                          {imageLoading && <LoadingBlock />}
                          <Controller
                            name="article"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <CustomReactQuill
                                {...field}
                                ref={quillRef}
                                className={'static-text-content-page'}
                                style={{
                                  height: 500,
                                  marginBottom: 60,
                                }}
                                theme="snow"
                                value={field.value}
                                formats={formats}
                                modules={modules}
                                onChange={(v) => field.onChange(v)}
                              />
                            )}
                          />
                          {/* <QuillEditorComponent /> */}
                          <FormErrorMessage>
                            {errors.article?.message}
                          </FormErrorMessage>
                        </div>
                      </FormGroup>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="row mb-3">
                  <div className="col-lg-12">
                    <h4 className="text-center">{errorMessage}</h4>
                  </div>
                </div>
                <div className="row d-flex justify-content-between align-items-center">
                  <div className="col-lg-2 mb-3">
                    <QButton
                      round
                      type="button"
                      size="lg"
                      loading={isLoadingSave}
                      onClick={handleSubmit((data) =>
                        saveOrPublishNews(data, NewsStatus.Saved),
                      )}
                      className="w-100 btn btn-secondary"
                    >
                      Save
                    </QButton>
                  </div>
                  <div className="col-lg-2 mb-3">
                    <QButton
                      round
                      type="button"
                      size="lg"
                      loading={isLoadingPublish}
                      onClick={handleSubmit((data) =>
                        saveOrPublishNews(data, NewsStatus.Published),
                      )}
                      className="w-100 btn btn-success"
                    >
                      Publish
                    </QButton>
                  </div>
                  <div className="col-lg-2 mb-3">
                    <QButton
                      type="button"
                      round
                      size="lg"
                      className="w-100 btn btn-info"
                      loading={isLoadingSave}
                      onClick={handleSubmit((data) => {
                        saveOrPublishNews(data, NewsStatus.Saved, () => {
                          let clientPreviewUrl =
                            // @ts-ignore
                            urlBasedOnEnv[process.env.REACT_APP_NODE_ENV]
                          window.open(
                            `${clientPreviewUrl}/news/preview/${data.slug}`,
                            '_blank',
                          )
                        })
                      })}
                    // onClick={() => {
                    //   let clientPreviewUrl =
                    //     // @ts-ignore
                    //     urlBasedOnEnv[process.env.REACT_APP_NODE_ENV]
                    //     alert(process.env.REACT_APP_NODE_ENV)
                    //   window.open(
                    //     `${clientPreviewUrl}/news/preview/testdata`,
                    //     '_blank',
                    //   )
                    // }}
                    >
                      Preview
                    </QButton>
                  </div>
                  <div className="col-lg-2 mb-3">
                    <Link to={'/'}>
                      <QButton
                        type="button"
                        round
                        size="lg"
                        className="w-100 btn btn-danger"
                      >
                        Cancel
                      </QButton>
                    </Link>
                  </div>
                  <div className="col-lg-2 mb-3">
                    {
                      operationState === NeweOperationStateType.Update && <QButton
                        type="button"
                        round
                        size="lg"
                        className="w-100 btn btn-success"
                        onClick={addNewArticle}
                      >
                        Add New
                      </QButton>}

                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div >
      </body >
    </>
  )
}

export default AddNews
