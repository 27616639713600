

import Footer from '../../component/Footer'
import './index.scss'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { FormGroup, Input, Label } from 'reactstrap'

import * as yup from 'yup'
import { toast } from 'react-toastify'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import QButton from '../../component/DesignSystem/Core/Common/QButton/QButton'
import { createPromotionEmail, editPromotionEmail, getSinglePromotionEmail } from '../../api'
import { CustomReactQuillV3 } from '../../component/misc/smallComponents'
import { FormErrorMessage } from '../../component'
import { PromoEmailDayType, PEcomponentAction, PromotionEmailInterface, PromoEmailRecipientType } from '../../utils/types'
import { capitalizedOneWord, promoEmailConfigCreator } from '../../utils/common'


export const promotionEmailSchemaYup = yup.object().shape({
  templateName: yup.string()
    .required('Template Name is required'),
  isActive: yup.boolean().required(),
  dayType: yup.number()
    .oneOf([
      PromoEmailDayType.ZERO,
      PromoEmailDayType.ONE,
      PromoEmailDayType.THREE,
      PromoEmailDayType.SEVEN
    ])
    .required('Day type is required'),
  recipientsType: yup.string()
    .required('Recipients type is required'),
  subject: yup.string()
    .required('Subject is required'),
  content: yup.string()
    .required('Content is required'),
});

export default function PromotionEmailAddAndEdit({
  ActionType
}: {
  ActionType: PEcomponentAction
}) {
  const { id } = useParams<{ id: string }>()
  const [data, setdata] = useState<PromotionEmailInterface | null>(null)

  const [Action, setAction] = React.useState<PEcomponentAction>(ActionType);

  const navigate = useNavigate()
  const [loading, setLoading] = React.useState(false)


  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<PromotionEmailInterface>({
    resolver: yupResolver(promotionEmailSchemaYup),
    // defaultValues: {
    //   templateName: "lorem ipsum",
    //   subject: "My Email subject",
    //   content: "My email content",
    // }
  })

  const getSingleData = async (id: string) => {
    console.log('id:', id)

    try {

      const response: any = await getSinglePromotionEmail(id)
      if (response.data) {
        const { data } = response;
        setAction(PEcomponentAction.EDIT)
        setdata(data);
        if (data) {
          setValue('templateName', data.templateName)
          setValue('isActive', data.isActive)
          setValue('recipientsType', data.recipientsType)
          setValue('dayType', data.dayType)
          setValue('subject', data.subject)
          setValue('content', data.content)

        }
      } else {
        navigate('/manual-promotion-email-list')
      }
    } catch (error: any) {
      setLoading(false)
      toast.error(error.response.data.message)
    }
  }

  useEffect(() => {
    if (id) {
      setAction(PEcomponentAction.EDIT)
      getSingleData(id)
    } else {
      setAction(PEcomponentAction.ADD)
    }
  }, [id, Action])

  const onSubmit: SubmitHandler<PromotionEmailInterface> = async (data) => {
    setLoading(true)
    try {
      if (Action === PEcomponentAction.ADD) {
        const response = await createPromotionEmail({...data, templateConfig: promoEmailConfigCreator(data)})

        // toast.success(response.data.message, {
        //   position: 'bottom-center',
        // })

      } else if (Action === PEcomponentAction.EDIT && id) {
        const response = await editPromotionEmail(id, {...data, templateConfig: promoEmailConfigCreator(data)})

        // toast.success(response.data.message, {
        //   position: 'bottom-center',
        // })
      }


      setLoading(false)
      navigate(data.dayType === PromoEmailDayType.ZERO ? '/manual-promotion-email-list' : '/auto-promotion-email-list')


    } catch (error: any) {
      console.log('error:', error)

      setLoading(false)
      toast.error(error.response.data.message, { position: 'bottom-center' })
    }
    setLoading(false)
  }



  return (
    <>



      <div className="">
        <div className="container ">
          <div className="row">
            <div className="col-md-12">
              <ol
                className="breadcrumb"
                style={{ paddingTop: '2rem', paddingBottom: '0.6rem' }}
              >
                <li className="breadcrumb-item">
                  <Link to="/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">{capitalizedOneWord(PEcomponentAction[ActionType])} Promotion Email Template</li>


              </ol>
            </div>

          </div>
          <div className="col-md-12 ">
            <div className="  ">

              <div className="row mt-4 pb-4 ">
                <div className="col-md-12 common-box-shadow">
                  <form className='p-2 pt-4' onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group mb-3">
                          <Label>
                            <b>Enter Template Name</b>
                          </Label>
                          <Controller
                            name="templateName"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <Input
                                style={{ marginBottom: '1rem' }}
                                type="text"
                                placeholder="Enter Email Template Name"
                                value={field.value}
                                onChange={field.onChange}
                              />
                            )}
                          />
                          {errors.templateName && (
                            <FormErrorMessage>{errors.templateName.message}</FormErrorMessage>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group mb-3">
                          <Label>
                            <b>Schedule Type</b>
                          </Label>
                          <Controller
                            name="dayType"
                            control={control}
                            defaultValue={PromoEmailDayType.ONE}
                            render={({ field }) => (
                              <Input
                                style={{ marginBottom: '1rem' }}
                                type="select"
                                placeholder="Enter Day Type"
                                value={field.value}
                                onChange={field.onChange}
                              >
                                {
                                  Object.values(PromoEmailDayType).filter(v => typeof v === "number").map((value) => {
                                    return <option value={value} key={value}>
                                      {/* {value} */}
                                      {value === 0 ? "Anytime - Manual Email Send" : `At day ${value} - Auto Email Send`}
                                    </option>

                                  })
                                }
                              </Input>
                            )}
                          />
                          
                          {errors.dayType && (
                            <FormErrorMessage>{errors.dayType.message}</FormErrorMessage>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group mb-3">
                          <Label>
                            <b>Recipients Type</b>
                          </Label>
                          <Controller
                            name="recipientsType"
                            control={control}
                            defaultValue={PromoEmailRecipientType.REGISTERED}
                            render={({ field }) => (
                              <Input
                                style={{ marginBottom: '1rem' }}
                                type="select"
                                placeholder="Enter recipients Type"
                                value={field.value}
                                onChange={field.onChange}
                              >
                                {
                                  Object.values(PromoEmailRecipientType).map((value) => {
                                    return <option value={value} key={value}>
                                      {value}
                                    </option>
                                  })
                                }
                              </Input>
                            )}
                          />
                          {errors.recipientsType && (
                            <FormErrorMessage>{errors.recipientsType.message}</FormErrorMessage>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group mb-3">
                          <Label>
                            <b>Active</b>
                          </Label>
                          <Controller
                            name="isActive"
                            control={control}
                            defaultValue={true}
                            render={({ field }) => (
                              <Input
                                style={{ marginBottom: '1rem' }}
                                type="select"
                                placeholder="Select"
                                value={field.value.toString()}
                                onChange={e => field.onChange(e.target.value === "true")}
                              >
                                <option value={"true"}>
                                  Active
                                </option>
                                <option value={"false"}>
                                  Inactive
                                </option>
                              </Input>
                            )}
                          />
                          {errors.isActive && (
                            <FormErrorMessage>{errors.isActive.message}</FormErrorMessage>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-12">

                        <div className="form-group mb-3">
                          <Label>
                            <b>Enter Email Subject</b>
                          </Label>
                          <Controller
                            name="subject"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <Input
                                style={{ marginBottom: '1rem' }}
                                type="text"
                                placeholder="Enter Email subject"
                                value={field.value}
                                onChange={field.onChange}
                              />
                            )}
                          />
                          {errors.subject && (
                            <FormErrorMessage>{errors.subject.message}</FormErrorMessage>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <FormGroup>
                          <label>Email Body content</label>
                          <div className="text-editor-wrapper">
                            <Controller
                              name="content"
                              control={control}
                              defaultValue=""
                              render={({ field }) => (
                                <CustomReactQuillV3
                                  {...field}
                                  className={'email-static-text-content-page'}
                                  theme="snow"
                                  value={field.value}
                                  onChange={(v: string) => field.onChange(v)}
                                  style={{
                                    height: 200,
                                    marginBottom: 60,
                                  }}
                                />
                              )}
                            />

                            <FormErrorMessage>
                              {errors.content?.message}
                            </FormErrorMessage>
                          </div>
                        </FormGroup>
                      </div>

                    </div>











                    <div className="text-start ms-auto" style={{ maxWidth: '250px' }}>
                      {/* <QButton
                        round
                        onClick={() => navigate('/viewLandProjects')}
                        className="me-3"
                      >
                        <b>Go Back</b>
                      </QButton> */}
                      <QButton round loading={loading} type="submit" >
                        <b>Submit</b>
                      </QButton>
                    </div>
                  </form>
                </div>


              </div>
            </div>
          </div>
        </div>
        <div className="row">

        </div>
      </div>

      

    </>
  )
}
