import {
  Credentials,
  User,
  OrderCreated,
  TransferUnit,
  TransferUnitCreated,
  Transaction,
  PayoutInfo,
  SuperAdmin,
  ExternalCardCryptoFeeFormData,
  ReferenceCode,
  News,
  LandProjectObjectInteface,
  NewsCategoryInterface,
  UserType,
  UnitType,
  PaymentMethodEnum,
  OrderType,
  OrderStatus,
  UnitPrices,
  StatiticsTotalFiatInBank,
  Statistics,
  CurrencyType,
  PromotionEmailInterface,
  EmailDeliveryTypeEnum,
  StaticContentInterface,
  SortFieldsEnumForTransactionHistoryQuery,
} from '../utils/types'
import http from '../services/httpService'
import { ProjectDetails, Question } from '../utils/types'
import axios from 'axios'
import { SortDirection } from '@mui/material'

const postApproveProfile = () =>
  http.post<{ user: User }>('/user/approve_profile')

const postUpdateProfile = (user: FormData) =>
  http.post<{ user: User }>('/user/update_profile', user)

const postUpdateBussinesProfile = (user: FormData) =>
  http.post<{ user: User }>('/user/update_business_profile', user)

const postResetPassword = (email: string) =>
  http.post('/auth/login/forgot', { email })

const postResetPasswordChange = (password: string, token: string) =>
  http.post<void>(`/auth/login/reset/${token}`, { password })
const getVerifyUserPasswordToken = (token: string) =>
  http.get<void>(`/auth/verify_password_token/${token}`)

const postLogin = (credentials: Credentials) =>
  http.post<{
    user: User
    message: string
  }>('/auth/login', credentials)

const postUnitTransfer = (transferUnit: TransferUnit) =>
  http.post<{ transferUnitCreated: TransferUnitCreated }>(
    '/user/transfer_unit',
    transferUnit,
  )

const postUpdateOrderAmount = (orderCreated: OrderCreated) =>
  http.post<{ orderUpdatedData: OrderCreated }>(
    '/user/update_order_amount',
    orderCreated,
  )

const postLogout = () => http.post<void>('/user/logout')

const createProject = (projectDetails: ProjectDetails) =>
  http.post(`/projectdetail/create_project`, projectDetails)

const createNews = (news: FormData) =>
  http.post<{ message: string; news: News }>(`/news/create`, news)
const addImage = (data: any) =>
  http.post<{ message: any }>(`/news/add-image`, data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })

const getAllUserAdminQuery = (searchType: string, searchTerm: string) =>
  http.get(
    `/user/getAllUserAdminQuery?searchType=${searchType}&searchTerm=${searchTerm}`,
  )

const putUpdateUserDetails = (user: User) =>
  http.put<{ message: string; user: User }>('/user/editUserDetails', user)
const postGovernanceQuestion = (data: {
  questionsList: Question[]
  selectedAnswers: string[]
}) => http.post<{ message: string }>('/governance', data)
const postApproveBuyStripeOrder = (data: { orderId: any }) =>
  http.post(`/order/buy/approve-stripe`, data)

const postApproveBuyBankOrder = (data: { orderId: any }) =>
  http.post(`/order/buy/approve-bank`, data)

const deleteInCompleteOrder = (orderId: string, reason: string) =>
  http.delete(`/order/buy/delete-incomplete/`, { data: { reason, orderId } })

const postDisApproveBuyOrder = (data: { orderId: any }) =>
  http.post(`/order/buy/disapprove`, data)

const postApproveBuyOtherOrder = (data: { orderId: any }) =>
  http.post(`/order/buy/approve-other`, data)
const postApprovePayout = (data: { payoutId: string }) =>
  http.post<PayoutInfo[]>(`/payout/buy/approve`, data)

const putApproveUser = (email: string) =>
  http.put(`/user/userVerified/${email}`)

const putApproveCommunityAgentUser = (email: string) =>
  http.put(`/user/approveCommunityAgent/${email}`)
const putAssignOrRemoveAdminRole = (roleAndUserId: {
  userId: string
  roles: string[]
}) => http.put(`/user/assignOrRemoveAdminRole/`, roleAndUserId)

const putEnableOrDisabledUser = (userId: string) =>
  http.put(`/user/toggleEnabledOrDisabledUser/${userId}`)
const deleteUser = (userId: string) => http.delete(`/user/delete/${userId}`)
const putProjectDetails = (projectdetails: Partial<ProjectDetails>) =>
  http.put(`/projectdetail/update_project/`, projectdetails)
const putNews = (news: FormData) => http.put(`/news/update/`, news)
const deleteNews = (newsId: string) => http.delete(`/news/delete/${newsId}`)
const sentNewsTo = (newsId: string, isMember: boolean) =>
  http.put<{ message: string }>(`/news/sentTo/`, { id: newsId, isMember })

const putDisapproveUser = (id: string, disapprovalReason: string) =>
  http.put<{ message: string }>(`/user/disApproveUser/`, {
    id,
    disapprovalReason,
  })

const putDisapproveUserCommunityAgent = (
  email: string,
  disapprovalReason: string,
) =>
  http.put<{ message: string }>(`/user/disApproveUserCommunityAgent/`, {
    email,
    disapprovalReason,
  })
const editGovernanceQuestion = (
  id: string,
  data: {
    questionsList: Question[]
    selectedAnswers: string[]
  },
) => http.put<{ message: string }>(`/governance/${id}`, data)
const deleteGovernanceQuestion = (id: string) =>
  http.delete<{ message: string }>(`/governance/${id}`)
const updateExternalFeesApi = (
  updatedExternalFees: ExternalCardCryptoFeeFormData,
) => http.put('/externalfee', updatedExternalFees)
const updateCurrencyUnitFeesApi = (formData: UnitPrices) =>
  http.put<{ message: string; data: UnitPrices }>('/currencyUnits', formData)

const getReferenceCodebyCode = (searchByReferenceCode: string) =>
  http.get(
    `/referencecode/searchByReferenceCode?referenceCode=${searchByReferenceCode}`,
  )

const putReferencecode = (referencecode: Partial<ReferenceCode>) =>
  http.put(`/referencecode/update_code/`, referencecode)

const postReferencecode = (referencecode: Partial<ReferenceCode>) =>
  http.post(`/referencecode/create_code/`, referencecode)
const getProjectByLandNameForEdit = (searchprojectByName: string) =>
  http.get(
    `/projectdetail/searchProjectNameForEdit?projectName=${searchprojectByName}`,
  )

const getSuperAdminByNameForView = (searchType: string, searchTerm: string) =>
  http.get(
    `/user/searchSuperAdminNameForView?searchType=${searchType}&searchTerm=${searchTerm}`,
  )
const getProjectByLandNameForView = (searchProjectByName: string) =>
  http.get(
    `/projectdetail/searchProjectNameForView?projectName=${searchProjectByName}`,
  )
const getNewsByTitle = (title: string) =>
  http.get<{ news: News[] }>(`/news/searchNewsByTitle?title=${title}`)
const getUserListByNameOrEmailForUpdate = (
  searchType: string,
  searchTerm: string,
  userType: UserType,
  isDownload: boolean = false,
) =>
  http.get(
    `/user/searchUserListByNameOrEmailForUpdate?searchType=${searchType}&searchTerm=${searchTerm}&userType=${userType}&isDownload=${isDownload}`,
  )

const getUserListByNameOrEmailForEnableDisable = (
  searchType: string,
  searchTerm: string,
  userType: UserType,
) =>
  http.get(
    `/user/searchUserListByNameOrEmailForEnableDisable?searchType=${searchType}&searchTerm=${searchTerm}&userType=${userType}`,
  )

const getUserListByNameOrEmailForDelete = (
  searchType: string,
  searchTerm: string,
  userType: UserType,
) =>
  http.get(
    `/user/searchUserListByNameOrEmailForDelete?searchType=${searchType}&searchTerm=${searchTerm}&userType=${userType}`,
  )

const getSubscriberListByEmail = (email: string, isDownload: boolean = false) =>
  http.get(
    `/subscribe/getSubscriberListByEmail?email=${email}&isDownload=${isDownload}`,
  )

const getExportUserList = () => http.get(`/user/exportUsers`)
const getUserOrderApprovedOrCompleted = (
  currentPage: number,
  pageSize: number,
  searchType: string,
  searchTerm: string,
  fromDate: string,
  toDate: string,
  unitType: UnitType | string,
  paymentMethod: PaymentMethodEnum | string,
  userType: UserType | string,
  orderType: OrderType | String,
  orderStatus: OrderStatus | String,
  sortBy: SortFieldsEnumForTransactionHistoryQuery,
  sortDirection: SortDirection,
  isDownload: boolean = false,
) =>
  http.get(
    `/order/retrieve/buy-sell/approve-completed?page=${currentPage}&pageSize=${pageSize}&searchType=${searchType}&searchTerm=${searchTerm}&fromDate=${fromDate}&toDate=${toDate}&unitType=${unitType}&userType=${userType}&paymentMethod=${paymentMethod}&orderType=${orderType}&orderStatus=${orderStatus}&sortBy=${sortBy}&sortDirection=${sortDirection}&isDownload=${isDownload}`,
  )

const getUserInCompleteOrder = (
  currentPage: number,
  pageSize: number,
  searchType: string,
  searchTerm: string,
  fromDate: string,
  toDate: string,
  unitType: UnitType | string,
  paymentMethod: PaymentMethodEnum | string,
  userType: UserType | string,
  orderType: OrderType | String,
  orderStatus: OrderStatus | String,
  sortBy: SortFieldsEnumForTransactionHistoryQuery,
  sortDirection: SortDirection,
  isDownload: boolean = false,
) =>
  http.get(
    `/order/retrieve/buy/incomplete?page=${currentPage}&pageSize=${pageSize}&searchType=${searchType}&searchTerm=${searchTerm}&fromDate=${fromDate}&toDate=${toDate}&unitType=${unitType}&userType=${userType}&paymentMethod=${paymentMethod}&orderType=${orderType}&orderStatus=${orderStatus}&sortBy=${sortBy}&sortDirection=${sortDirection}&isDownload=${isDownload}`,
  )

const getUserbuyOrderPaymentSentWithoutCardAndBank = (
  currentPage: number,
  pageSize: number,
  searchType: string,
  searchTerm: string,
  fromDate: string,
  toDate: string,
  userType: UserType | string,
  unitType: UnitType | string,
  sortBy: SortFieldsEnumForTransactionHistoryQuery,
  sortDirection: SortDirection,
  isDownload: boolean = false,
) =>
  http.get(
    `/order/retrieve/buy/payment-sent/without-card-bank?page=${currentPage}&pageSize=${pageSize}&searchType=${searchType}&searchTerm=${searchTerm}&fromDate=${fromDate}&toDate=${toDate}&userType=${userType}&unitType=${unitType}&sortBy=${sortBy}&sortDirection=${sortDirection}&isDownload=${isDownload}`,
  )

const getUserbuyOrderPaymentSentWithBank = (
  currentPage: number,
  pageSize: number,
  searchType: string,
  searchTerm: string,
  fromDate: string,
  toDate: string,
  userType: UserType,
  unitType: UnitType,
  sortBy: SortFieldsEnumForTransactionHistoryQuery,
  sortDirection: SortDirection,
  isDownload: boolean = false,
) =>
  http.get(
    `/order/retrieve/buy/payment-sent/with-bank?page=${currentPage}&pageSize=${pageSize}&searchType=${searchType}&searchTerm=${searchTerm}&userType=${userType}&unitType=${unitType}&fromDate=${fromDate}&toDate=${toDate}&sortBy=${sortBy}&sortDirection=${sortDirection}&isDownload=${isDownload}`,
  )

const getuserSellOrderAllUnitSold = (
  currentPage: number,
  pageSize: number,
  searchType: string,
  searchTerm: string,
  fromDate: string,
  toDate: string,
  unitType: UnitType | string,
  userType: UserType | string,
  sortBy: SortFieldsEnumForTransactionHistoryQuery,
  sortDirection: SortDirection,
  isDownload: boolean = false,
) =>
  http.get(
    `/order/retrieve/sell/all-unit-sold?page=${currentPage}&pageSize=${pageSize}&searchType=${searchType}&searchTerm=${searchTerm}&fromDate=${fromDate}&toDate=${toDate}&unitType=${unitType}&userType=${userType}&sortBy=${sortBy}&sortDirection=${sortDirection}&isDownload=${isDownload}`,
  )

const getuserSellOrder = (
  currentPage: number,
  pageSize: number,
  searchType: string,
  searchTerm: string,
  fromDate: string,
  toDate: string,
  unitType: UnitType | string,
  userType: UserType | string,
  orderStatus: OrderStatus | string,
  sortBy: SortFieldsEnumForTransactionHistoryQuery,
  sortDirection: SortDirection,
  isDownload: boolean = false,
) =>
  http.get(
    `/order/retrieve/sell?page=${currentPage}&pageSize=${pageSize}&searchType=${searchType}&searchTerm=${searchTerm}&fromDate=${fromDate}&toDate=${toDate}&unitType=${unitType}&userType=${userType}&orderStatus=${orderStatus}&sortBy=${sortBy}&sortDirection=${sortDirection}&isDownload=${isDownload}`,
  )
const postApproveSellOrder = (data: { orderId: any }) =>
  http.post<{}>(`/order/sell/approve`, data)
const updateFiatInBank = (currencyType: CurrencyType, fiatValue: number) =>
  http.put<{ message: string }>('/statistics/update-fiat-in-bank', {
    currencyType,
    fiatValue,
  })
const getViewOrDownloadFileFromAwsS3ForProfile = (
  currentUserEmailViewFile: string,
  documentName: string,
  isDownload: boolean,
) =>
  http.get(
    '/user/getViewOrDownloadFileFromAwsS3/' +
      currentUserEmailViewFile +
      '/' +
      documentName +
      '/' +
      isDownload,
  )
const getViewOrDownloadFileFromAwsS3ForNews = (
  newsId: string,
  isDownload: boolean,
) =>
  http.get('/news/getViewOrDownloadFileFromAwsS3/' + newsId + '/' + isDownload)
const getStatistics = () => http.get<Statistics>(`/statistics/`)
const getTotalFiatInBank = () =>
  http.get<StatiticsTotalFiatInBank>(`/statistics/get-total-fiat-in-bank`)
const getUsersTransactions = (
  currentPage: number,
  pageSize: number,
  searchType: string,
  searchTerm: string,
  fromDate: string,
  toDate: string,
  orderStatus: OrderStatus | string,
  paymentMethod: PaymentMethodEnum | string,
  orderType: OrderType | string,
  userType: UserType | string,
  unitType: UnitType | string,
  sortBy: SortFieldsEnumForTransactionHistoryQuery,
  sortDirection: SortDirection,
  isDownload: boolean = false,
) =>
  http.get(
    `/order/retrieve/all?page=${currentPage}&pageSize=${pageSize}&searchType=${searchType}&searchTerm=${searchTerm}&fromDate=${fromDate}&toDate=${toDate}&orderStatus=${orderStatus}&orderType=${orderType}&paymentMethod=${paymentMethod}&userType=${userType}&unitType=${unitType}&sortBy=${sortBy}&sortDirection=${sortDirection}&isDownload=${isDownload}`,
  )
const getPendingVerificationUsers = (
  searchType: string,
  searchTerm: string,
  userType: UserType,
) =>
  http.get(
    `/user/searchUserPendingVerificationListByNameOrEmail?searchType=${searchType}&searchTerm=${searchTerm}&userType=${userType}`,
  )

const getPendingCommunityAgentUsers = (
  searchType: string,
  searchTerm: string,
  isDownload: boolean = false,
) =>
  http.get(
    `/user/searchUserCommunityAgentPendingVerificationListByNameOrEmail?searchType=${searchType}&searchTerm=${searchTerm}&isDownload=${isDownload}`,
  )
const getVerifiedCommunityAgentUsers = (
  searchType: string,
  searchTerm: string,
  isDownload: boolean = false,
) =>
  http.get(
    `/user/searchUserCommunityAgentVerifiedListByNameOrEmail?searchType=${searchType}&searchTerm=${searchTerm}&isDownload=${isDownload}`,
  )
const getVerificationUsers = (
  searchType: string,
  searchTerm: string,
  userType: UserType,
  isDownload: boolean = false,
) =>
  http.get<{ users: User[] } | any>(
    `/user/searchUserVerifiedListByNameOrEmail?searchType=${searchType}&searchTerm=${searchTerm}&userType=${userType}&isDownload=${isDownload}`,
  )
const getUserAdminOrSuperAdmin = () =>
  http.get<{
    user: User | SuperAdmin
  }>('/userorsuperadmin')
const getGovernanceQuestion = (id: string) =>
  http.get<{ questionsList: Question[] }>(`/governance/${id}`)

const getTransactions = (page: number, pageSize: number) =>
  http.get<{
    myTransactions: Transaction[]
  }>('/user/getTransactions', { params: { page, pageSize } })

const getUserNameByEmail = (email: string) =>
  http.get<{ name: string }>('/user/getUserNameByEmail', { params: { email } })

const getUserOrSuperAdmin = () => http.get(`/user/userorsuperadmin`)
const getExternalfee = () => http.get<{ data: any }>('/externalfee')
const getCurrencyUnitsApi = () => http.get<UnitPrices[]>('/currencyUnits')
const getGovernanceQuestions = () =>
  http.get<{ questionsList: Question[] }>(`/governance/`)
const getAllLandProjects = () =>
  http.get<{ dataList: LandProjectObjectInteface[] }>(`/land-project`)
const getAllNewsCategory = () =>
  http.get<{ dataList: NewsCategoryInterface[] }>(`/news/news-category`)
const getProjectIdeas = () =>
  http.get<{ questionsList: Question[] }>(`/project/projectIdeas`)

const getUsersForPayouts = (
  currentPage: number,
  pageSize: number,
  searchType: string,
  searchTerm: string,
  fromDate: string,
  toDate: string,
  userType: UserType | string,
  isDownload: boolean = false,
) =>
  http.get<PayoutInfo[] | any>(
    `/payout/retrieve/buy/payment-sent/with-card?page=${currentPage}&pageSize=${pageSize}&searchType=${searchType}&searchTerm=${searchTerm}&fromDate=${fromDate}&toDate=${toDate}&userType=${userType}&isDownload=${isDownload}`,
  )
const getAllCommunityIdea = () => http.get(`/projectIdeas`)
const revalidateNextJsPages = (url: string) =>
  axios.get(url, {
    headers: {
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  })
const revalidateNextJsStaticPages = (url: string) =>
  axios.get(url, {
    headers: {
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  })

const getAllPromotionEmailByDayTypes = (
  emailDeliveryType: EmailDeliveryTypeEnum,
) =>
  http.get<{ dataList: PromotionEmailInterface[] }>(
    `/promotion-email?emailDeliveryType=${emailDeliveryType}`,
  )

const createPromotionEmail = (data: PromotionEmailInterface) =>
  http.post<{ data: PromotionEmailInterface }>('/promotion-email', data)
const sendInstantPromotionEmail = (data: PromotionEmailInterface) =>
  http.post<{ data: PromotionEmailInterface }>(
    '/promotion-email/send-instant-promotion-email',
    data,
  )

const getSinglePromotionEmail = (id: string) =>
  http.get<{ data: PromotionEmailInterface }>(`/promotion-email/` + id)
const getStats = () =>
  http.get<{ data: StaticContentInterface }>(`/utility/get-states`)

const setStats = (data: StaticContentInterface) =>
  http.put<{ data: StaticContentInterface }>(`/utility/set-states`, data)

const editPromotionEmail = (id: string, data: PromotionEmailInterface) =>
  http.put<{ message: string }>(`/promotion-email/${id}`, data)

const deletePromotionEmail = (id: string) =>
  http.delete<{ message: string }>(`/promotion-email/${id}`)

export {
  getStats,
  setStats,
  getAllPromotionEmailByDayTypes,
  createPromotionEmail,
  getSinglePromotionEmail,
  editPromotionEmail,
  deletePromotionEmail,
  sendInstantPromotionEmail,
  getAllLandProjects,
  revalidateNextJsPages,
  revalidateNextJsStaticPages,
  getAllNewsCategory,
  getAllCommunityIdea,
  getProjectIdeas,
  getUserOrSuperAdmin,
  postLogin,
  postLogout,
  postResetPassword,
  getUserAdminOrSuperAdmin,
  getTransactions,
  getUserNameByEmail,
  postUpdateOrderAmount,
  postUnitTransfer,
  postUpdateProfile,
  postApproveProfile,
  postUpdateBussinesProfile,
  addImage,
  createProject,
  getStatistics,
  getUsersTransactions,
  getPendingVerificationUsers,
  putApproveUser,
  getViewOrDownloadFileFromAwsS3ForProfile,
  getVerifyUserPasswordToken,
  putAssignOrRemoveAdminRole,
  getAllUserAdminQuery,
  putEnableOrDisabledUser,
  putProjectDetails,
  getProjectByLandNameForEdit,
  getProjectByLandNameForView,
  putUpdateUserDetails,
  getUserListByNameOrEmailForUpdate,
  getUserListByNameOrEmailForEnableDisable,
  postGovernanceQuestion,
  getGovernanceQuestion,
  editGovernanceQuestion,
  getGovernanceQuestions,
  deleteGovernanceQuestion,
  getUsersForPayouts,
  postApproveBuyStripeOrder,
  postApproveBuyBankOrder,
  postApproveBuyOtherOrder,
  postApprovePayout,
  postResetPasswordChange,
  getExternalfee,
  getCurrencyUnitsApi,
  updateExternalFeesApi,
  updateCurrencyUnitFeesApi,
  getUserOrderApprovedOrCompleted,
  getUserbuyOrderPaymentSentWithBank,
  getUserbuyOrderPaymentSentWithoutCardAndBank,
  getuserSellOrderAllUnitSold,
  getuserSellOrder,
  postApproveSellOrder,
  getVerificationUsers,
  putDisapproveUser,
  updateFiatInBank,
  getReferenceCodebyCode,
  putReferencecode,
  postReferencecode,
  getSuperAdminByNameForView,
  postDisApproveBuyOrder,
  getNewsByTitle,
  createNews,
  getViewOrDownloadFileFromAwsS3ForNews,
  putNews,
  deleteNews,
  sentNewsTo,
  getUserListByNameOrEmailForDelete,
  deleteUser,
  getExportUserList,
  getSubscriberListByEmail,
  getPendingCommunityAgentUsers,
  getVerifiedCommunityAgentUsers,
  putApproveCommunityAgentUser,
  putDisapproveUserCommunityAgent,
  getTotalFiatInBank,
  getUserInCompleteOrder,
  deleteInCompleteOrder,
}
