import React, { useEffect, useState } from 'react'
import Header from '../../component/Sidbar'
import Footer from '../../component/Footer'
import { Link } from 'react-router-dom'

import moment from 'moment'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import QButton from '../../component/DesignSystem/Core/Common/QButton/QButton'
import { toast } from 'react-toastify'
import { testAxios } from '../../utils/constants'
import { ProjectSuggestionInteface } from '../../utils/types'
import { getAllCommunityIdea } from '../../api'
export default function ViewCommunityIdeas() {
  const [modal, setModal] = useState(false)

  const toggle = () => setModal(!modal)
  const [deleteModal, setdeleteModal] = useState(false)
  const [deleteLoading, setdeleteLoading] = useState(false)
  const toogleDeleteModal = () => {
    setdeleteModal(!deleteModal)
  }

  const [data, setData] = useState<ProjectSuggestionInteface[]>([])

  const [activeIdea, setactiveIdea] =
    useState<null | ProjectSuggestionInteface>(null)
  const [activeID, setactiveID] = useState<string>('')

  const DeleteSuggestion = async () => {
    try {
      setdeleteLoading(true)
      const url = process.env.REACT_APP_SERVER_URI + `/projectIdeas/${activeID}`
      const response = await testAxios.delete(url)
      setdeleteLoading(false)
      toogleDeleteModal()
      toast.success(response.data.message, { position: 'bottom-center' })
      getAllData()
    } catch (e) {
      console.log('e:', e)
    }
  }

  const getAllData = async () => {
    try {
      const response: any = await getAllCommunityIdea()
      if (response) {
        setData(response.data)
      }
    } catch (e) {
      console.log('e:', e)
    }
  }
  useEffect(() => {
    getAllData()
  }, [])

  return (
    <>
      {/* <div>{showChildComponent && <EditQuestions data={id} />}</div> */}
      <body className="fixed-nav sticky-footer dash-img-bg" id="page-top">
        

        <div className="content-wrapper ">
          <div className="container-fluid">
            <ol
              className="breadcrumb"
              style={{ paddingTop: '2rem', paddingBottom: '0.6rem' }}
            >
              <li className="breadcrumb-item">
                <Link to="/">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">View Community Ideas</li>
            </ol>
            <table
              className="table table-bordered"
              
              width="100%"
              cellSpacing="0"
            >
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th>Name </th>
                  <th>Email </th>

                  <th>Submitted at</th>

                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data.map(
                  (project: ProjectSuggestionInteface, index: number) => {
                    return (
                      <tr key={index}>
                        <td>
                          <b>{index + 1}.</b>
                        </td>
                        <td>
                          <b>{project.name}</b>
                        </td>
                        <td>
                          <b>{project.email}</b>
                        </td>

                        <td>
                          <b>
                            {moment(project.createdAt).format('DD-MM-YYYY')}
                          </b>
                        </td>

                        <td>
                          <QButton
                            size="sm"
                            onClick={() => {
                              if (project._id) {
                                setactiveIdea(project)
                                toggle()
                              }
                            }}
                            round
                            className="me-1"
                          >
                            View
                          </QButton>
                          <QButton
                            size="sm"
                            onClick={() => {
                              if (project._id) {
                                setactiveID(project._id)
                                toogleDeleteModal()
                              }
                            }}
                            round
                          >
                            Delete
                          </QButton>

                          {/* <EditIcon /> */}
                        </td>
                        {/* <td
                    
                      >
                        <DeleteOutlineIcon />
                      </td> */}
                      </tr>
                    )
                  },
                )}
              </tbody>
            </table>
            <Modal
              isOpen={modal}
              toggle={toggle}
              centered
              scrollable
              onClosed={() => {}}
            >
              <ModalHeader toggle={toggle}>
                Idea From : {activeIdea?.name}{' '}
              </ModalHeader>
              <ModalBody>
                <div className="idea-block-list">
                  {activeIdea?.projectIdea && (
                    <div className="idea-block">
                      <h4>Project Idea</h4>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: activeIdea?.projectIdea,
                        }}
                      ></div>
                    </div>
                  )}

                  {activeIdea?.voteTopicIdea && (
                    <div className="idea-block">
                      <h4>Voting Topic Idea</h4>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: activeIdea?.voteTopicIdea,
                        }}
                      ></div>
                    </div>
                  )}
                </div>
              </ModalBody>
              <ModalFooter>
                <QButton round onClick={toggle}>
                  Close
                </QButton>
              </ModalFooter>
            </Modal>
            <Modal
              isOpen={deleteModal}
              toggle={toogleDeleteModal}
              className="modal-dialog-centered "
              scrollable
              // size="lg"
              unmountOnClose={true}
            >
              <ModalHeader>Confirm.</ModalHeader>
              <ModalBody>Do you want to delete this Entry ?</ModalBody>

              <ModalFooter>
                <QButton round className="me-3" onClick={toogleDeleteModal}>
                  Close
                </QButton>
                <QButton
                  round
                  loading={deleteLoading}
                  onClick={DeleteSuggestion}
                >
                  Delete
                </QButton>
              </ModalFooter>
            </Modal>
          </div>
        </div>

        
      </body>
    </>
  )
}
