import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ProjectDetails } from '../../utils/types'
import { ONE_AUD_TO_CENTS } from '../../utils/utilities'
import { getProjectByLandNameForView } from '../../api'
import SearchInput from '../../component/DesignSystem/Core/Common/SearchInput/SearchInput'
import { FormGroup } from 'reactstrap'
import { toast } from 'react-toastify'

export default function ViewAllLand() {
  const [projectDetails, setProjectDetails] = useState<ProjectDetails[]>([])
  const [searchprojectByName, setSearchProjectByName] = useState<string>('')

  const getProjectByLandName = async () => {
    getProjectByLandNameForView(searchprojectByName)
      .then((res) => {
        setProjectDetails(res.data)
      })
      .catch((err) => {
        console.log('apis error====', err)
        toast.error(err.response.data.message)
      })
  }
  useEffect(() => {
    getProjectByLandName()
  }, [])

  return (
    <>
      <body className="fixed-nav sticky-footer dash-img-bg" id="page-top">
        <div className="content-wrapper ">
          <div className="container-fluid">
            <ol
              className="breadcrumb"
              style={{ paddingTop: '2rem', paddingBottom: '0.6rem' }}
            >
              <li className="breadcrumb-item">
                <Link to="/">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">View Project Details</li>
            </ol>

            <div className="card mb-3">
              {/* <div className="card-header">
                                <i className="fa fa-list-ul me-2"></i> List of All Transactions
                            </div> */}
              <div className="card-body">
                <div className="table-responsive">
                  <FormGroup className="search-by-wrap">
                    <label
                      className="me-sm-2 mb-0 text-nowrap"
                      htmlFor="serachProjectName"
                    >
                      Search by Project Name
                    </label>
                    <SearchInput
                      onClick={getProjectByLandName}
                      value={searchprojectByName}
                      onChange={(e) => setSearchProjectByName(e.target.value)}
                      // loading={userLoading}
                    />
                  </FormGroup>
                  <table
                    className="table table-bordered text-nowrap text-center"
                    
                    width="100%"
                    cellSpacing="0"
                  >
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Name</th>
                        <th>Cadaster</th>
                        <th>Entered by</th>
                        <th>Country</th>
                      </tr>
                    </thead>
                    <tbody>
                      {projectDetails.map((projectDetail, index: number) =>
                        projectDetail.projectId ? (
                          <tr key={projectDetail.projectId}>
                            <td>
                              <b>{index + 1}.</b>
                            </td>
                            <td>{projectDetail.projectName}</td>

                            <td>{projectDetail.projectCadaster}</td>
                            <td>
                              <p>
                                {projectDetail.landEnteredBy?.bySuperAdmin &&
                                  projectDetail.landEnteredBy.bySuperAdmin
                                    .firstName +
                                    ' ' +
                                    projectDetail.landEnteredBy.bySuperAdmin
                                      .lastName}
                                {projectDetail.landEnteredBy?.byUserAdmin &&
                                  projectDetail.landEnteredBy.byUserAdmin
                                    .firstName +
                                    ' ' +
                                    projectDetail.landEnteredBy.byUserAdmin
                                      .lastName}
                              </p>
                              <p>
                                {
                                  projectDetail.landEnteredBy?.bySuperAdmin
                                    ?.email
                                }
                                {
                                  projectDetail.landEnteredBy?.byUserAdmin
                                    ?.email
                                }
                              </p>
                            </td>
                            <td>{projectDetail.projectAddress.country}</td>
                          </tr>
                        ) : null,
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  )
}
