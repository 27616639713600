import React, { useState } from 'react'
import '../../App.css'
import './index.scss'
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Form, FormGroup } from 'reactstrap'
import * as yup from 'yup'
import {
  ONE_USD_TO_CENTS,
  ProjectDetails,
  UnitDisplayNames,
  UnitType,
} from '../../utils/types'
import { createProject } from '../../api'
import QButton from '../../component/DesignSystem/Core/Common/QButton/QButton'
import { FormErrorMessage } from '../../component/FormErrorMessage'

import { toast } from 'react-toastify'

const schema = yup.object().shape({
  projectName: yup
    .string()
    .required('Project Name is required')
    .label('Project Name'),
  projectValueInCentsByUnit: yup
    .object()
    .shape({
      [UnitType.LAND_AUD]: yup.number().required().min(0).label('Land AUD'),
      [UnitType.LAND_EUR]: yup.number().required().min(0).label('Land EUR'),
      [UnitType.LAND_USD]: yup.number().required().min(0).label('Land USD'),
      [UnitType.LAND_POUND]: yup.number().required().min(0).label('Land Pound'),
    })
    .required('Project Value in Cents by Unit is required'),

  projectCadaster: yup
    .string()
    .required('Project Cadaster is required')
    .label('Project Cadaster'),
  projectDescription: yup
    .string()
    .required('Project Description is required')
    .label('Project Description'),
  projectAddress: yup.object().shape({
    street: yup.string().required('Street is required').label('Street'),
    city: yup.string().required('City is required').label('City'),
    postal: yup.string().required('Postal is required').label('Postal'),
    state: yup.string().required('State is required').label('State'),
    country: yup.string().required('Country is required').label('Country'),
  }),
})

const initialProjectLandValues = {
  projectName: '', // Empty string for text fields
  projectCadaster: '',
  projectDescription: '',
  projectAddress: {
    street: '',
    city: '',
    postal: '',
    state: '',
    country: '',
  },
  projectValueInCentsByUnit: {
    [UnitType.LAND_AUD]: 0, // Default numeric values to 0
    [UnitType.LAND_EUR]: 0,
    [UnitType.LAND_USD]: 0,
    [UnitType.LAND_POUND]: 0,
  },
}
const AddNewLand: React.FC = () => {
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors, isSubmitted, isValid },
  } = useForm<ProjectDetails>({
    resolver: yupResolver(schema),
    defaultValues: initialProjectLandValues,
  })
  const [isLoading, setIsLoading] = useState(false)
  const [saveMessage, setSaveMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const onSubmit = async (projectDetails: ProjectDetails) => {
    if (!projectDetails) return
    setIsLoading(true)
    setErrorMessage('')
    createProject(projectDetails)
      .then(async ({ data: { message } }) => {
        setSaveMessage(message)
        setTimeout(() => {
          setSaveMessage('')
          setIsLoading(false)
          navigate('/dashboard/land/view')
        }, 700)
      })
      .catch((err) => {
        setIsLoading(false)
        setErrorMessage(err.response.data.message)
        toast.error(err.response.data.message, {
          position: 'bottom-center',
        })

        console.log('error', err)
      })
  }

  return (
    <>
      <body className="fixed-nav sticky-footer dash-img-bg" id="page-top">
        <div className="content-wrapper ">
          <div className="container-fluid">
            <ol className="breadcrumb">
              <li
                className="breadcrumb-item"
                style={{
                  paddingTop: '2rem',
                  paddingBottom: '0.6rem',
                }}
              >
                <Link to="/">Dashboard</Link>
              </li>
              <li
                className="breadcrumb-item active"
                style={{
                  paddingTop: '2rem',
                  paddingBottom: '0.6rem',
                }}
              >
                Add New Land
              </li>
            </ol>
            <Form
              // className={`row ${!isValid ? "needs-validation" : ""}  ${
              //   isSubmitted ? "was-validated" : ""
              // }`}
              className={`row `}
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="col-lg-12">
                <div className="box_general padding_bottom add-land-form">
                  <div className="header_box version_2">
                    <h2>
                      <i className="fa fa-plus "></i>Enter Land Details
                    </h2>
                  </div>
                  {saveMessage && (
                    <div className="save-message">{saveMessage}</div>
                  )}

                  <div className="row">
                    <div className="col-md-9">
                      <FormGroup>
                        <label>Land Project Name</label>
                        <input
                          type="text"
                          className="form-control"
                          {...register('projectName')}
                        />
                        <FormErrorMessage>
                          {errors.projectName?.message}
                        </FormErrorMessage>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <label>Cadaster Number</label>
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            className="form-control"
                            {...register('projectCadaster')}
                          />
                          <FormErrorMessage>
                            {errors.projectCadaster?.message}
                          </FormErrorMessage>
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-12">
                      <FormGroup>
                        <label>Description</label>
                        <textarea
                          className="form-control"
                          {...register('projectDescription')}
                        ></textarea>
                        <FormErrorMessage>
                          {errors.projectDescription?.message}
                        </FormErrorMessage>
                      </FormGroup>
                    </div>
                    <div className="row">
                      {Object.keys(UnitType).map((unit) => (
                        <div key={unit} className="col-lg-3">
                          <FormGroup>
                            {/* @ts-ignore */}
                            <label>{UnitDisplayNames[unit]}</label>
                            <input
                              type="number"
                              {...register(
                                `projectValueInCentsByUnit.${unit}`,
                                {
                                  setValueAs: (value) => {
                                    if (value === '' || isNaN(value)) {
                                      return 0 // Treat empty or invalid values as 0
                                    }
                                    return Math.round(Number(value) * 100) // Multiply by 100 and round
                                  },
                                },
                              )}
                              onBlur={(e) => {
                                if (e.target.value === '') {
                                  setValue(
                                    `projectValueInCentsByUnit.${unit}`,
                                    0,
                                  ) // Set to default
                                }
                              }}
                              className={`form-control ${errors.projectValueInCentsByUnit?.[unit] ? 'is-invalid' : ''}`}
                            />
                            {errors.projectValueInCentsByUnit?.[unit] && (
                              <FormErrorMessage>
                                {
                                  errors.projectValueInCentsByUnit[unit]
                                    ?.message
                                }
                              </FormErrorMessage>
                            )}
                          </FormGroup>
                        </div>
                      ))}
                    </div>

                    <div className="col-md-6">
                      <FormGroup>
                        <label>Street Addres</label>
                        <input
                          type="text"
                          className="form-control"
                          {...register('projectAddress.street')}
                        />
                        <FormErrorMessage>
                          {errors.projectAddress?.street?.message}
                        </FormErrorMessage>
                      </FormGroup>
                    </div>
                    <div className="col-md-6">
                      <FormGroup>
                        <label>City or Locality</label>
                        <input
                          type="text"
                          className="form-control"
                          {...register('projectAddress.city')}
                        />
                        <FormErrorMessage>
                          {errors.projectAddress?.city?.message}
                        </FormErrorMessage>
                      </FormGroup>
                    </div>
                    <div className="col-md-4">
                      <FormGroup>
                        <label>Postal Code</label>
                        <input
                          type="text"
                          className="form-control"
                          {...register('projectAddress.postal')}
                        />
                        <FormErrorMessage>
                          {errors.projectAddress?.postal?.message}
                        </FormErrorMessage>
                      </FormGroup>
                    </div>

                    <div className="col-md-4">
                      <FormGroup>
                        <label>State</label>
                        <input
                          type="text"
                          className="form-control"
                          {...register('projectAddress.state')}
                        />
                        <FormErrorMessage>
                          {errors.projectAddress?.state?.message}
                        </FormErrorMessage>
                      </FormGroup>
                    </div>
                    <div className="col-md-4">
                      <FormGroup>
                        <label>Country</label>
                        <input
                          type="text"
                          className="form-control"
                          {...register('projectAddress.country')}
                        />
                        <FormErrorMessage>
                          {errors.projectAddress?.country?.message}
                        </FormErrorMessage>
                      </FormGroup>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <h4 className="textCenter">{errorMessage}</h4>

                    <QButton type="submit" round size="lg" loading={isLoading}>
                      Submit
                    </QButton>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </body>
    </>
  )
}

export default AddNewLand
