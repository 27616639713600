import React, { useEffect } from "react";
import "./notFound.scss";
import imageUrl from "./page-not-found.webp";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
	const navigate = useNavigate();

	useEffect(() => {
		navigate("/");
	}, []);
	return (
		<div className="page-not-found">
			<img src={imageUrl} alt="404" className="image404" />
		</div>
	);
};

export default NotFound;
