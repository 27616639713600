import React, { useState } from 'react'
import Header from '../Sidbar'
import Footer from '../Footer'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import SingleDatePickerComponent from '../SingleDatePickerComponent'
import { FormGroup, Input, Label } from 'reactstrap'
import moment from 'moment'
import QButton from '../DesignSystem/Core/Common/QButton/QButton'
import * as yup from 'yup'
import {
  ProjectColorSatus,
  ProjectObjectInteface,
  SingleQuestionInterface,
  VoteTopicObjectInteface,
} from '../../utils/types'
import { validateProjectData } from '../../utils/utilities'
import { toast } from 'react-toastify'
import {
  projectStatusObject,
  testAxios,
  voteQuestionSingle,
} from '../../utils/constants'
import {
  IsProjectCompleteOptionsComponent,
  ProjectStatusOptionList,
} from '../misc/smallComponents'

import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormErrorMessage } from '../FormErrorMessage'

export interface ProjectFormValues {
  projectName: string
  projectDescription: string
  projectManager: string
  projectTimeStatus: string
  projectBudgetStatus: string
  projectQualityStatus: string
  startDate: string
  endDate: string
  IsProjectComplete: string
}

export const projectSchema = yup.object().shape({
  projectName: yup.string().required('Project Name is required'),
  projectDescription: yup.string().required('Project Description is required'),
  projectManager: yup.string().required('Project Manager is required'),
  projectTimeStatus: yup.string().required('Project Time Status is required'),
  projectBudgetStatus: yup
    .string()
    .required('Project Budget Status is required'),
  projectQualityStatus: yup
    .string()
    .required('Project Quality Status is required'),
  startDate: yup.string().required('Start Date is required'),
  endDate: yup.string().required('End Date is required'),
  IsProjectComplete: yup.string().required('Is Project completed is required'),
})

const ProjectAdd: React.FC = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = React.useState(false)

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ProjectFormValues>({
    resolver: yupResolver(projectSchema),
  })

  const onSubmit: SubmitHandler<ProjectFormValues> = async (data) => {
    const url = `${process.env.REACT_APP_SERVER_URI}/project`

    try {
      setLoading(true)

      const response = await testAxios.post(url, {
        ...data,
        IsProjectComplete: data.IsProjectComplete === 'true',
      })
      toast.success(response.data.message, {
        position: 'bottom-center',
      })
      setLoading(false)
      navigate('/viewProjects')
    } catch (error: any) {
      console.log('error:', error)
      setLoading(false)
      toast.error(error.response.data.message, { position: 'bottom-center' })
    }
  }

  return (
    <div className="row mt-4 pb-4">
      <div className="col-md-12">
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Label>
              <b>Select Project Date Range:</b>
            </Label>
            <div className="d-flex">
              <Controller
                name="startDate"
                control={control}
                defaultValue={moment().format('YYYY-MM-DD')}
                render={({ field }) => (
                  <SingleDatePickerComponent
                    isBefore={false}
                    setDate={(d) => field.onChange(d.format('YYYY-MM-DD'))}
                    date={field.value ? moment(field.value) : null}
                    id={'startDate'}
                    placeholder="Start date"
                  />
                )}
              />
              {errors.startDate && (
                <FormErrorMessage>{errors.startDate.message}</FormErrorMessage>
              )}
              <Controller
                name="endDate"
                control={control}
                defaultValue={moment().format('YYYY-MM-DD')}
                render={({ field }) => (
                  <SingleDatePickerComponent
                    isBefore={false}
                    setDate={(d) => field.onChange(d.format('YYYY-MM-DD'))}
                    date={field.value ? moment(field.value) : null}
                    id={'endDate'}
                    placeholder="End date"
                  />
                )}
              />
              {errors.endDate && (
                <FormErrorMessage>{errors.endDate.message}</FormErrorMessage>
              )}
            </div>
          </FormGroup>
          <FormGroup>
            <Label>
              <b>Enter Project Time Status</b>
            </Label>
            <Controller
              name="projectTimeStatus"
              control={control}
              defaultValue={projectStatusObject.green.key}
              render={({ field }) => (
                <Input
                  name="projectTimeStatus"
                  type="select"
                  value={field.value}
                  onChange={field.onChange}
                >
                  <ProjectStatusOptionList />
                </Input>
              )}
            />
            {errors.projectTimeStatus && (
              <FormErrorMessage>
                {errors.projectTimeStatus.message}
              </FormErrorMessage>
            )}
          </FormGroup>
          <FormGroup>
            <Label>
              <b>Project Completion status </b>
            </Label>
            <Controller
              name="IsProjectComplete"
              control={control}
              defaultValue={'false'}
              render={({ field }) => (
                <Input
                  name="IsProjectComplete"
                  type="select"
                  value={field.value}
                  onChange={field.onChange}
                >
                  <IsProjectCompleteOptionsComponent />
                </Input>
              )}
            />
            {errors.projectTimeStatus && (
              <FormErrorMessage>
                {errors.projectTimeStatus.message}
              </FormErrorMessage>
            )}
          </FormGroup>
          <FormGroup>
            <Label>
              <b>Enter Project Budget Status</b>
            </Label>
            <Controller
              name="projectBudgetStatus"
              control={control}
              defaultValue={projectStatusObject.green.key}
              render={({ field }) => (
                <Input
                  name="projectBudgetStatus"
                  type="select"
                  value={field.value}
                  onChange={field.onChange}
                >
                  <ProjectStatusOptionList />
                </Input>
              )}
            />
            {errors.projectBudgetStatus && (
              <FormErrorMessage>
                {errors.projectBudgetStatus.message}
              </FormErrorMessage>
            )}
          </FormGroup>
          <FormGroup>
            <Label>
              <b>Enter Project Quality Status</b>
            </Label>
            <Controller
              name="projectQualityStatus"
              control={control}
              defaultValue={projectStatusObject.green.key}
              render={({ field }) => (
                <Input
                  name="projectQualityStatus"
                  type="select"
                  value={field.value}
                  onChange={field.onChange}
                >
                  <ProjectStatusOptionList />
                </Input>
              )}
            />
            {errors.projectQualityStatus && (
              <FormErrorMessage>
                {errors.projectQualityStatus.message}
              </FormErrorMessage>
            )}
          </FormGroup>
          <div className="form-group mb-3">
            <Label>
              <b>Enter Project Name</b>
            </Label>
            <Controller
              name="projectName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  style={{ marginBottom: '1rem' }}
                  type="text"
                  placeholder="Enter Project Name"
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            {errors.projectName && (
              <FormErrorMessage>{errors.projectName.message}</FormErrorMessage>
            )}
          </div>
          <div className="form-group mb-3">
            <Label>
              <b>Enter Project Manager</b>
            </Label>
            <Controller
              name="projectManager"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  className="form-control"
                  type="text"
                  style={{ marginBottom: '1rem' }}
                  placeholder="Enter Project Manager"
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            {errors.projectManager && (
              <FormErrorMessage>
                {errors.projectManager.message}
              </FormErrorMessage>
            )}
          </div>
          <div className="form-group mb-3">
            <Label>
              <b>Enter Project Description</b>
            </Label>
            <Controller
              name="projectDescription"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <textarea
                  className="form-control"
                  style={{ marginBottom: '1rem' }}
                  placeholder="Enter Project Description"
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            {errors.projectDescription && (
              <FormErrorMessage>
                {errors.projectDescription.message}
              </FormErrorMessage>
            )}
          </div>
          <div className="text-start ms-auto" style={{ maxWidth: '250px' }}>
            <QButton round loading={loading} type="submit" fullWidth>
              <b>Submit</b>
            </QButton>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ProjectAdd
