import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  getUserListByNameOrEmailForEnableDisable,
  putEnableOrDisabledUser,
} from '../../api'
import SearchInput from '../../component/DesignSystem/Core/Common/SearchInput/SearchInput'
import { FormGroup } from 'reactstrap'
import { User, UserOrBusinessProps, UserType } from '../../utils/types'
import { getDate } from '../../utils/utilities'

export default function DisableEnableUserOrBusiness({
  userType,
}: UserOrBusinessProps) {
  const [data, setData] = useState('')
  const [id, setId] = useState<string>()
  const [searchType, setSearchType] = useState('name') // Default search type is name
  const [searchTerm, setSearchTerm] = useState('')

  const getUsersOrBusiness = () => {
    getUserListByNameOrEmailForEnableDisable(searchType, searchTerm, userType)
      .then((res) => {
        setData(res.data.users)
      })
      .catch((err) => {
        console.log('error=============', err)
        toast.error(err.response.data.message)
      })
  }
  useEffect(() => {
    getUsersOrBusiness()
  }, [])

  const handleEnabledOrDisableUsers = (id: any) => {
    putEnableOrDisabledUser(id)
      .then((res) => {
        toast.success(res.data.message)
        getUsersOrBusiness()
      })
      .catch((err) => {
        toast.error(err.response.data.message)
        console.log('error=============', err)
      })
  }
  return (
    <>
      <body className="fixed-nav sticky-footer dash-img-bg" id="page-top">
        <div className="content-wrapper ">
          <div className="container-fluid">
            <ol
              className="breadcrumb"
              style={{ paddingTop: '2rem', paddingBottom: '0.6rem' }}
            >
              <li className="breadcrumb-item">
                <Link to="/">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">
                ENABLE/DISABLE {userType}
              </li>
            </ol>

            <div className="card mb-3">
              <div className="card-body">
                <div className="table-responsive">
                  <FormGroup className="search-by-wrap">
                    <select
                      className="form-control"
                      value={searchType}
                      onChange={(e) => setSearchType(e.target.value)}
                    >
                      <option value="name">Search {userType} by Name</option>
                      <option value="email">Search {userType} by Email</option>
                    </select>

                    <SearchInput
                      onClick={getUsersOrBusiness}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      ButtonText="Search"
                      placeholder={'Search'}
                    // loading={userLoading}
                    />
                  </FormGroup>
                  <table
                    className="table table-bordered"
                    
                    width="100%"
                    cellSpacing="0"
                  >
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>
                          {userType === UserType.BUSINESS
                            ? 'Authorised Person '
                            : ''}
                          Name
                        </th>
                        {userType === UserType.BUSINESS && (
                          <th>Business Name</th>
                        )}{' '}
                        <th>Email</th>
                        <th>Date Joined</th>
                        <th>Phone Number</th>
                        <th>Enable/Disable Users</th>
                      </tr>
                    </thead>
                    <tbody>
                    {Array.isArray(data) &&
                      data.map((item: User, index: number) => {
                        return (
                          <tr>
                            <td>
                              <b>{index + 1}.</b>
                            </td>
                            <td>{item?.firstName + ' ' + item?.lastName}</td>
                            {userType === UserType.BUSINESS && (
                              <td>{item.kycInfo.businessInfo?.businessName}</td>
                            )}
                            <td>{item?.email}</td>
                            <td>{getDate(item.createdAt)}</td>
                            <td>
                              {item?.kycInfo.contactInfo.telephoneCountryCode +
                                item?.kycInfo.contactInfo.phoneNumber}
                            </td>
                            <td onClick={() => setId(item._id)}>
                              {item?.isVerified === true && item.isEnabled ? (
                                <button
                                  className="btn btn-danger bg-danger btn-sm "
                                  onClick={() => {
                                    handleEnabledOrDisableUsers(item._id)
                                  }}
                                >
                                  Disable{' '}
                                  {userType === UserType.BUSINESS
                                    ? "Business"
                                    : "Person"}
                                </button>
                              ) : (
                                <button
                                  className="btn btn-sm btn-success "
                                  style={{ backgroundColor: '#157347' }}
                                  onClick={() => {
                                    handleEnabledOrDisableUsers(item._id)
                                  }}
                                >
                                  Enable{' '}
                                  {userType === UserType.BUSINESS
                                    ? "Business"
                                    : "Person"}
                                </button>
                              )}
                            </td>

                          </tr>
                        )
                      })}
                    </tbody>
                  
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  )
}
