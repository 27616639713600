import React, { useEffect, useState } from 'react'
import { Link, Navigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import '../../App'
import {
  ResetPasswordFormValues,
  ResetPasswordRequestFormValues,
} from '../../utils/types'
import { getVerifyUserPasswordToken, postResetPasswordChange } from '../../api'
import { useForm } from 'react-hook-form'
import { FormErrorMessage } from '../../component'

const ResetPasswordPage: React.FC = () => {
  const imageUrl = 'images/4858794.jpg' // Path to your image

  const divStyle = {
    backgroundImage: `url(${imageUrl})`,
    // Adjust width and height as needed
    // Add other styles if needed
  }
  const [isDropdownOpen, setDropdownOpen] = useState(false)

  const [auth, setAuth] = useState<string>('')
  const [serverError, setServerError] = useState('')
  const { token } = useParams<{ token: string }>()
  const [verifyTokenErrorMessage, setVerifyTokenErrorMessage] = useState('')
  const [isPasswordChanged, setIsPasswordChanged] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isVerifyTokenLoading, setIsVerifyTokenLoading] = useState(true)

  const validationSchema = Yup.object({
    password: Yup.string()
      .min(5, 'Password must be at least 5 characters')
      .max(255)
      .required('Required'),
  })

  const initialValues: ResetPasswordFormValues = {
    password: '',
  }
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<ResetPasswordFormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  })

  const onSubmit = (values: ResetPasswordFormValues) => {
    setServerError('')
    setIsLoading(true)

    const password = values.password
    setIsLoading(true)
    postResetPasswordChange(password, token as string)
      .then((res) => {
        console.log('res', res)
        setIsPasswordChanged(true)
        setIsLoading(false)
      })
      .catch((err: any) => {
        console.log(err)
        setIsLoading(false)
        setServerError(err.response?.data.message)
      })
  }

  const handleDropdownToggle = () => {
    setDropdownOpen(!isDropdownOpen)
  }
  useEffect(() => {
    if (!token) {
      ;<Navigate to={'/'} replace />
    }
    setIsVerifyTokenLoading(false)
    const fetchUserPasswordToken = async () => {
      getVerifyUserPasswordToken(token as string)
        .then(() => setIsVerifyTokenLoading(true))
        .catch((error) => {
          const errorMessage =
            error.response.data.message || 'An error occurred.'
          setVerifyTokenErrorMessage(errorMessage)
          setIsVerifyTokenLoading(true)
        })
    }

    fetchUserPasswordToken()
  }, [])
  return (
    <>
      <div id="page">
        <nav
          className="dash-nav navbar navbar-expand-lg navbar-dark  fixed-top align-items-center justify-content-between"
          id="mainNav"
        >
          <div className="d-flex align-items-center align-items-lg-end">
            <div className="dropdown me-3">
              {auth ? (
                <button
                  className="p-0 btn dropdown-toggle quan-toggle shadow-none"
                  type="button"
                  id="dropdownMenuButton2"
                  data-bs-toggle="dropdown"
                  aria-expanded={isDropdownOpen}
                  onClick={handleDropdownToggle}
                >
                  <i className="ti-menu"></i>
                </button>
              ) : (
                ''
              )}
              <ul
                className={`dropdown-menu quan-menu ${isDropdownOpen ? 'show' : ''}`}
                aria-labelledby="dropdownMenuButton2"
              >
                <li>
                  <Link to="/" className="dropdown-item">
                    Dashboard
                  </Link>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Quantum Mandate
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Buy Land Units
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    About Us
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Contact
                  </a>
                </li>
              </ul>
            </div>
            <div className="dropdown p-0 d-block d-lg-none ms-sm-5 ms-4">
              <button
                className="p-0 btn dropdown-toggle quan-toggle"
                type="button"
                id="dropdownMenuButton2"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="ti-user mobile-user"></i>
              </button>
              <ul
                className="dropdown-menu quan-menu"
                aria-labelledby="dropdownMenuButton2"
              >
                <li>
                  <a className="dropdown-item" href="log-in.html">
                    LOG-IN
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="register.html">
                    REGISTER
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    LOG-OUT
                  </a>
                </li>
              </ul>
            </div>
            {auth ? (
              <ul className="navbar-nav align-items-end d-none d-lg-flex">
                <li className="nav-item">
                  <a
                    href="#"
                    className="nav-link ms-3"
                    data-toggle="modal"
                    data-target="#exampleModal"
                  >
                    LOG-OUT
                  </a>
                </li>
              </ul>
            ) : (
              <ul className="navbar-nav align-items-end d-none d-lg-flex ">
                <li className="nav-item ">
                  <Link to="/login" className="nav-link ms-3">
                    LOG-IN
                  </Link>
                </li>
                {/* <li className="nav-item">
                    <Link to='/register' className="nav-link ms-3">
                      REGISTER </Link>
                  </li> */}
              </ul>
            )}
          </div>
          <a
            className="navbar-brand ms-auto p-0 d-none d-lg-block"
            href="index.html"
          >
            <img src="images/quantum-logo.png" alt="" height="36" />
          </a>
          <div className="d-lg-none d-block dash-mobile-logo">
            <a href="index.html">
              <img src="images/quantum-logo.png" alt="" />
            </a>
          </div>
        </nav>

        <main className="">
          <section className="sec-img" style={divStyle}>
            <div
              className="container margin_60"
              style={{ paddingTop: '150px' }}
            >
              <div className="row justify-content-center">
                <div className="col-xl-6 col-lg-6 col-md-8">
                  <div className="login-from-q">
                    {!isVerifyTokenLoading ? (
                      <p>Loading...</p> // Render loading text while token verification is in progress
                    ) : (
                      // Render relevant content once token verification is complete
                      <>
                        {verifyTokenErrorMessage ? (
                          <FormErrorMessage>
                            {verifyTokenErrorMessage}
                          </FormErrorMessage>
                        ) : (
                          <>
                            {isPasswordChanged ? (
                              <>
                                <div className="banner-text">
                                  Your password has been changed successfully.
                                  You can login now with the new password.
                                </div>
                                <Link
                                  className="btn_1 btn_hover_yellow text-white rounded full-width"
                                  style={{ marginTop: '20px' }}
                                  to="/login/"
                                >
                                  Login
                                </Link>
                              </>
                            ) : (
                              <form onSubmit={handleSubmit(onSubmit)}>
                                <p>
                                  Enter Your desired password and click on Reset
                                  Password
                                </p>
                                <div className="form-group mb-3">
                                  <label htmlFor="password">Password</label>
                                  <input
                                    {...register('password')}
                                    type="password"
                                    className="form-control"
                                    name="password"
                                    id="password"
                                  />
                                  {errors.password && (
                                    <FormErrorMessage>
                                      {errors.password.message}
                                    </FormErrorMessage>
                                  )}
                                </div>
                                {serverError && (
                                  <FormErrorMessage>
                                    {serverError}
                                  </FormErrorMessage>
                                )}
                                <div className="text-center">
                                  <button
                                    className="btn_1 rounded full-width color"
                                    type="submit"
                                    // className="btn_1 btn_hover_yellow text-white rounded full-width"
                                    disabled={isLoading}
                                  >
                                    Reset Password
                                  </button>
                                </div>
                              </form>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </>
  )
}

export default ResetPasswordPage
