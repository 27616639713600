import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './viewOrEditFiat.scss'
import { toast } from 'react-toastify'
import { getTotalFiatInBank, updateFiatInBank } from '../../api'
import { CurrencyType, StatiticsTotalFiatInBank } from '../../utils/types'
import { ONE_USD_TO_CENTS } from '../../utils/constants'
export default function ViewOrEditFiat() {
  const [fiatInBank, setFiatInBank] = useState<StatiticsTotalFiatInBank | null>(
    null,
  )
  const [fiatInBankByCurrency, setFiatInBankByCurrency] = useState<Record<
    CurrencyType,
    number
  > | null>(null)

  const [originalFiatValues, setOriginalFiatValues] = useState<
    Record<CurrencyType, number>
  >({} as Record<CurrencyType, number>)

  const [loadingState, setLoadingState] = useState<
    Record<CurrencyType, boolean>
  >({} as Record<CurrencyType, boolean>)

  // Handle input changes
  const handleInputFiatInBank = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, value } = event.target
    setFiatInBankByCurrency((prev) => ({
      ...prev!,
      [name]: value === '' ? 0 : parseFloat(value) * ONE_USD_TO_CENTS || 0, // Ensure value is a number
    }))
  }

  const getTotalFiatInBanks = async () => {
    getTotalFiatInBank()
      .then((res) => {
        setFiatInBank(res.data)
        const initialFiatValues = Object.entries(
          res.data.totalFiatInBankInCents,
        ).reduce(
          (acc, [currency, value]) => {
            acc[currency as CurrencyType] = value
            return acc
          },
          {} as Record<CurrencyType, number>,
        )
        setOriginalFiatValues(initialFiatValues)
        setFiatInBankByCurrency(initialFiatValues)
      })
      .catch((err) => {
        console.log('apis error====', err)
      })
  }

  // Update Fiat in Bank
  const handleUpdateFiat = async (currency: CurrencyType) => {
    const updatedValue = fiatInBankByCurrency?.[currency] as number
    const isValueChanged =
      updatedValue !== originalFiatValues[currency as CurrencyType]

    if (!isValueChanged)
      return alert(`please update the ${currency} value,then click update`)
    setLoadingState((prev) => ({ ...prev, [currency]: true })) // Set loading for the clicked currency

    updateFiatInBank(currency, updatedValue)
      .then(async ({ data: { message } }) => {
        toast.success(message)
        await getTotalFiatInBanks()
      })
      .catch((err) => {
        console.error('Error updating fiat value:', err)
        toast.error(err.response.data.message, { position: 'bottom-center' })
      })
      .finally(() => {
        setLoadingState((prev) => ({ ...prev, [currency]: false })) // Reset loading for the clicked currency
      })
  }
  useEffect(() => {
    getTotalFiatInBanks()
  }, [])
  return (
    <>
      <body className="fixed-nav sticky-footer dash-img-bg" id="page-top">
        <div className="content-wrapper ">
          <div className="container-fluid">
            <ol
              className="breadcrumb"
              style={{ paddingTop: '2rem', paddingBottom: '0.6rem' }}
            >
              <li className="breadcrumb-item">
                <Link to="/">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">View/Edit Fiat in Bank</li>
            </ol>
            <div className="card mb-3">
              <div className="card-body">
                <div className="table-responsive">
                  <table
                    className="table table-bordered text-center"
                    
                    width="100%"
                    cellSpacing="0"
                  >
                    <thead>
                      <tr>
                        <th>S.NO.</th>
                        <th>Currency</th>
                        <th>Value</th>
                        <th>Update</th>
                      </tr>
                    </thead>
                    <tbody>
                      {fiatInBankByCurrency &&
                        Object.entries(fiatInBankByCurrency).map(
                          ([currency, value], index) => (
                            <tr key={currency}>
                              <td>{index + 1}</td>
                              <td>{currency}</td>
                              <td>
                                <input
                                  type="number"
                                  name={currency}
                                  value={value / ONE_USD_TO_CENTS}
                                  onChange={handleInputFiatInBank}
                                  className="form-control"
                                />
                              </td>
                              <td>
                                <button
                                  className="btn btn-primary"
                                  onClick={() =>
                                    handleUpdateFiat(currency as CurrencyType)
                                  }
                                  disabled={
                                    loadingState[currency as CurrencyType] ||
                                    false
                                  }
                                >
                                  Update
                                </button>
                              </td>
                            </tr>
                          ),
                        )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  )
}
