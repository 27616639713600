import { isEmpty } from "lodash";

interface ErrorProps {
  children?: React.ReactElement | string;
}

export const FormErrorMessage = ({ children }: ErrorProps): React.ReactElement => {
  return isEmpty(children) ? (
    <></>
  ) : (
    <div className="form-error-message">
      <b>*{children}</b>
    </div>
  );
};
