import React, { useCallback, useEffect, useState } from 'react'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import { FormGroup, Input, Label } from 'reactstrap'
import { toast } from 'react-toastify'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import QButton from '../DesignSystem/Core/Common/QButton/QButton'
import {
  LandProjectObjectInteface,
  LandProjectTypes,
  NewsCategoryInterface,
  ProjectObjectInteface,
} from '../../utils/types'
import { validateProjectData } from '../../utils/utilities'
import { projectStatusObject, testAxios } from '../../utils/constants'
import {
  CustomReactQuillV3,
  IsProjectCompleteOptionsComponent,
  ProjectStatusOptionList,
} from '../misc/smallComponents'
import { FormErrorMessage } from '../FormErrorMessage'
import { CategorySchema, NewsCategoryFormValues } from './NewsCategoryAdd'
import { addImage } from '../../api'

import { init_revalidate_next_pages, stringToSlug } from '../../utils/common'

const NewsCategoryEdit: React.FC = () => {
  const toggle_doc_modal = () => setdoc_Modal(!doc_modal)
  const [doc_modal, setdoc_Modal] = useState(false)
  const navigate = useNavigate()
  const { id } = useParams<{ id: string }>()
  const [loading, setLoading] = useState(false)
  const [data, setdata] = useState(null)

  const {
    control,
    handleSubmit,
    getValues,
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<NewsCategoryFormValues>({
    resolver: yupResolver(CategorySchema),
  })

  useEffect(() => {
    if (id) {
      getSingleData()
    }
  }, [id])
  const name = watch('name')
  useEffect(() => {
    if (name) {
      const slug = stringToSlug(name)
      setValue('slug', slug)
    }
  }, [name, setValue])
  const getSingleData = async () => {
    try {
      const url = `${process.env.REACT_APP_SERVER_URI}/news/news-category/${id}`
      const response = await testAxios.get(url)
      const data: NewsCategoryInterface = response.data

      if (response.data) {
        setdata(response.data)
        setValue('name', data.name)
        setValue('slug', data.slug)


      } else {
        navigate('/news/viewNewsCategory/')
      }
    } catch (error: any) {
      setLoading(false)
      toast.error(error.response.data.message)
    }
  }

  const onSubmit: SubmitHandler<NewsCategoryFormValues> = async (data) => {
    if (validateProjectData(data)) {
      const url = `${process.env.REACT_APP_SERVER_URI}/news/news-category/${id}`

      try {
        setLoading(true)

        const response = await testAxios.put(url, data)
        toast.success(response.data.message, {
          position: 'bottom-center',
        })
        setLoading(false);
        init_revalidate_next_pages()
        navigate('/news/viewNewsCategory/')
      } catch (error: any) {
        setLoading(false)
        toast.error(error.response.data.message, { position: 'bottom-center' })
      }
    } else {
      toast.error('Please enter all data', {
        position: 'bottom-center',
      })
    }
  }



  return (
    <div className="row mt-4 pb-4">
      <div className="col-md-12">
        <form onSubmit={handleSubmit(onSubmit)}>



          <div className="form-group mb-3">
            <Label>
              <b>Enter Category Name</b>
            </Label>
            <Controller
              name="name"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  style={{ marginBottom: '1rem' }}
                  type="text"
                  placeholder="Enter Category Name"
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            {errors.name && (
              <FormErrorMessage>{errors.name.message}</FormErrorMessage>
            )}
          </div>
          <div className="form-group mb-3">
            <Label>
              <b>Enter slug</b>
            </Label>
            <Controller
              name="slug"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  style={{ marginBottom: '1rem' }}
                  type="text"
                  placeholder="Enter Category slug"
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            {errors.slug && (
              <FormErrorMessage>{errors.slug.message}</FormErrorMessage>
            )}
          </div>



          <div className="text-start ms-auto" style={{ maxWidth: '250px' }}>
            <QButton
              round
              onClick={() => navigate('/news/viewNewsCategory/')}
              className="me-3"
            >
              <b>Go Back</b>
            </QButton>
            <QButton round loading={loading} type="submit" >
              <b>Submit</b>
            </QButton>
          </div>
        </form>
      </div>


    </div>
  )
}

export default NewsCategoryEdit
