import React, { useState } from 'react'
import Header from '../Sidbar'
import Footer from '../Footer'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import SingleDatePickerComponent from '../SingleDatePickerComponent'
import { FormGroup, Input, Label } from 'reactstrap'
import moment from 'moment'
import QButton from '../DesignSystem/Core/Common/QButton/QButton'
import {
  SingleQuestionInterface,
  VoteTopicObjectInteface,
} from '../../utils/types'
import { validateAdminVotetopicData } from '../../utils/utilities'
import { toast } from 'react-toastify'
import { testAxios, voteQuestionSingle } from '../../utils/constants'

export default function VoteTopicAdd() {
  const [msg, setMsg] = useState<string>('')
  const [loading, setLoading] = useState(false)
  const [voteQuestions, setVoteQuestionsList] = useState<
    SingleQuestionInterface[]
  >([
    {
      ...voteQuestionSingle,
    },
  ])

  const [voteTopicTitle, setVoteTopicTitle] = useState<string>('')
  const [voteStatus, setvoteStatus] = useState<string>('InProgress')
  const [voteDescription, setvoteDescription] = useState<string>('')
  const [startDate, setstartDate] = useState<string>(
    moment().format('YYYY-MM-DD'),
  )
  const navigate = useNavigate()
  const [endDate, setendDate] = useState<string>(moment().format('YYYY-MM-DD'))

  const addNewQuestion = (index: number) => {
    setVoteQuestionsList((prev) => {
      const newArray = [...prev]
      newArray.splice(index + 1, 0, { ...voteQuestionSingle })
      return [...newArray]
    })
  }
  const deleteQuestion = (index: number): void => {
    setVoteQuestionsList((prev) => {
      const newArray = [...prev]
      if (newArray.length === 1) {
        toast.error('Last item', {
          position: 'bottom-center',
        })
        return [...prev]
      }
      newArray.splice(index, 1)
      return [...newArray]
    })
  }

  const handleFirstLevelValues = (
    index: number,
    key: string,
    value: string,
  ) => {
    setVoteQuestionsList((prev) => {
      const updatedList = [...prev]

      updatedList[index] = {
        ...updatedList[index],
        [key]: value,
      }

      return updatedList
    })
  }
  const handleSecondValues = (index: number, key: string, value: string) => {
    setVoteQuestionsList((prev) => {
      const updatedList = [...prev]

      updatedList[index] = {
        ...updatedList[index],
        answerOptions: {
          ...updatedList[index].answerOptions,
          [key]: value,
        },
      }

      return updatedList
    })
  }

  const handleSubmit = async () => {
    const data: VoteTopicObjectInteface = {
      voteTopicTitle,
      voteStatus,
      voteDescription,
      startDate,
      endDate,
      voteQuestions,
    }
    if (validateAdminVotetopicData(data)) {
      const url = process.env.REACT_APP_SERVER_URI + '/governance'

      setLoading(true)

      await testAxios
        .post(url, data)
        .then((res) => {
          setMsg(res.data.message)
          toast.success(res.data.message, {
            position: 'bottom-center',
          })
          setLoading(false)
          setMsg('');
          navigate("/viewQuestion")
        })
        .catch((error: any) => {
          console.log('error:', error)
          setLoading(false)
          toast.error(error.response.data.message, {
            position: 'bottom-center',
          })
        })
    } else {
      toast.error('Please enter all data', {
        position: 'bottom-center',
      })
    }
  }
  return (
    <div>
      <div className="row mt-4">
        <div className="col-md-12">
          <div className="form-group mb-3">
            <div className="form-group mb-3 col-md-12">
              <div className="form-group mb-3">
                <Label>
                  <b> Enter Voting Title</b>
                </Label>
                <Input
                  style={{ marginBottom: '1rem' }}
                  type="text"
                  placeholder="Enter Voting Title"
                  value={voteTopicTitle}
                  onChange={(e) => setVoteTopicTitle(e.target.value)}
                />
              </div>
              <div className="form-group mb-3">
                <Label>
                  <b> Enter Vote Description</b>
                </Label>
                <textarea
                  className="form-control"
                  style={{ marginBottom: '1rem' }}
                  placeholder="Enter Vote Description"
                  value={voteDescription}
                  onChange={(e) => setvoteDescription(e.target.value)}
                />
              </div>
              <Label>
                <b> Select Vote Date Range:</b>
              </Label>
              <div className="d-flex">
                <SingleDatePickerComponent
                  setDate={(d) => setstartDate(d.format('YYYY-MM-DD'))}
                  date={startDate ? moment(startDate) : null}
                  id={'startDate'}
                  placeholder="Start date"
                />
                <SingleDatePickerComponent
                  setDate={(d) => setendDate(d.format('YYYY-MM-DD'))}
                  date={endDate ? moment(endDate) : null}
                  id={'endDate'}
                  placeholder="End date"
                />
              </div>
            </div>
            <div className="col-md-12 ">
              <div className="vote-questions-list mt-5">
                <h3>Questions List</h3>
                {voteQuestions.map((q, qi) => {
                  return (
                    <div className="single-question">
                      <h6>Question {qi + 1}</h6>
                      <FormGroup>
                        <Input
                          value={q.questionTitle}
                          onChange={(e) =>
                            handleFirstLevelValues(
                              qi,
                              'questionTitle',
                              e.target.value,
                            )
                          }
                        />
                      </FormGroup>
                      <div className="question-options">
                        <h6>Select the right answer</h6>
                        {['A', 'B', 'C', 'D'].map((key) => (
                          <Label className="input-group" for={`${key + qi}`}>
                            <div className="input-group-text">
                              <Label className='mb-0 me-1' for={`${key + qi}`}>{key} </Label>
                              <input
                                id={key + qi}
                                type="radio"
                                aria-label="Radio button for following text input"
                                value={key}
                                checked={key === q.answerKey}
                                onChange={(e) =>
                                  handleFirstLevelValues(
                                    qi,
                                    'answerKey',
                                    e.target.value,
                                  )
                                }
                              />
                            </div>

                            <input
                              className="form-control"
                              key={key}
                              type="text"
                              placeholder={`Enter the option of ${key}`}
                              value={
                                q.answerOptions[key as 'A' | 'B' | 'C' | 'D']
                              }
                              onChange={(e) =>
                                handleSecondValues(qi, key, e.target.value)
                              }
                            />
                          </Label>
                        ))}
                      </div>

                      <div
                        className="d-flex mt-3 justify-content-end"
                        role="group"
                        aria-label="Basic example"
                      >
                        <QButton
                          disabled={voteQuestions.length === 0}
                          round
                          className="btn btn-primary me-3"
                          onClick={() => deleteQuestion(qi)}
                        >
                          <i className="fa fa-trash me-2"></i>
                          Delete
                        </QButton>
                        <QButton
                          round
                          className="btn btn-primary"
                          onClick={() => addNewQuestion(qi)}
                        >
                          <i className="fa fa-plus-circle me-2"></i>
                          Add New Question
                        </QButton>
                      </div>
                      {/* <div className="d-flex">
                              <button
                                className="btn_1 medium handleBtn"
                                onClick={() => deleteQuestion(i)}
                              >
                                Delete Question
                              </button>
                              <button
                                className="btn_1 medium handleBtn"
                                style={{ marginLeft: '1rem' }}
                                onClick={() => addNewQuestion(i)}
                              >
                                Add New Question
                              </button>
                            </div> */}
                    </div>
                  )
                })}
                {/* {questionsList.map((q, qIndex) => (
                        <div key={qIndex} className="question">
                          <input
                            className="form-control"
                            style={{ marginBottom: '1rem' }}
                            type="text"
                            placeholder="Enter question"
                            value={q.question}
                            onChange={(e) =>
                              handleQuestionChange(qIndex, e.target.value)
                            }
                          />
                          {['A', 'B', 'C', 'D'].map((key) => (
                            <input
                              className="form-control"
                              style={{ marginBottom: '1rem' }}
                              key={key}
                              type="text"
                              placeholder={`Enter the option of ${key}`}
                              value={q.options.answerKey}
                              onChange={(e) => {
                                handleOptionChange(qIndex, key, e.target.value)
                              }}
                            />
                          ))}
                          <h2>
                            Q.{'  '}
                            {q.question}
                          </h2>
                          {Object.entries(q.options).map(
                            ([key, optionValue], oIndex) => (
                              <div key={oIndex}>
                                <label>
                                  <b
                                    style={{
                                      fontSize: '1.3rem',
                                      color: '#000',
                                    }}
                                  >
                                    {key}
                                    {'      '}{' '}
                                  </b>
                                  <input
                                    type="radio"
                                    value={key}
                                    name={qIndex + 'group'}
                                    checked={
                                      checkedOptions[qIndex]?.answerKey ===
                                      key
                                    }
                                    onChange={() =>
                                      handleOptionChange2(
                                        qIndex,
                                        key,
                                        optionValue,
                                      )
                                    }
                                  />
                                  {`   ${optionValue}`}
                                </label>
                              </div>
                            ),
                          )}
                          <div className="row">
                            <div className="col-md-12 handlebtn">
                              <button
                                className="btn_1 medium handleBtn"
                                onClick={() => deleteQuestion(qIndex)}
                              >
                                Delete Question
                              </button>
                              <button
                                className="btn_1 medium handleBtn"
                                style={{ marginLeft: '1rem' }}
                                onClick={addNewQuestion}
                              >
                                Add New Question
                              </button>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12 btn">
                              <h4 className="textCenter">{msg}</h4>
                            </div>
                          </div>
                        </div>
                      ))} */}
              </div>

              <div className="text-start ms-auto" style={{ maxWidth: '250px' }}>
                <QButton
                  round
                  loading={loading}
                  onClick={handleSubmit}
                  fullWidth
                >
                  <b>Submit</b>
                </QButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}
