import { useEffect, useState } from 'react'
import {
  UnitType,
  ONE_USD_TO_CENTS,
  OrderStatus,
  PayoutInfo,
  UnitDisplayNames,
  CurrencyDisplaySymbol,
  CurrencyType,
  SortDirection,
  SortFieldsEnumForTransactionHistoryQuery,
} from '../../utils/types'
import { HasToolTipLabel, SortableTableHeader } from '../misc/smallComponents'
import {
  formatNumberWithCommas,
  formatNumberWithCommasUptoTwoDecimalWithCeil,
} from '../../utils/utilities'

export default function SinglePayoutComponent({
  payout,
  index,
  setOrderId,
  toggle,
}: {
  payout: PayoutInfo
  index: number
  setOrderId: (val: string) => void
  toggle: () => void
}) {
  const [state, setState] = useState<any>({
    sortDirection: SortDirection.desc,
    dataList: payout.userInfo,
    filteredData: [],
    searchTerm: '',
    searchType: 'name',
    unitType: '',
    numberOfUnits: '',
    paymentMethod: '',
    fromDate: '',
    toDate: '',
    sortBy: SortFieldsEnumForTransactionHistoryQuery.CreatedAt,
  })

  useEffect(() => {
    filterAndSortOrders()
  }, [
    state.unitType,
    state.numberOfUnits,
    state.paymentMethod,
    state.startDate,
    state.endDate,
    state.sortBy,
    state.sortDirection,
    state.dataList.length,
  ])

  const filterAndSortOrders = () => {
    let filtered = state.dataList

    if (state.unitType) {
      filtered = filtered.filter(
        (order: any) => order.unitType === state.unitType,
      )
    }

    if (state.sortBy) {
      filtered = filtered.sort((a: any, b: any) => {
        let comparison = 0

        if (state.sortBy === 'units') {
          const unitsA = a?.numberOfUnits || 0
          const unitsB = b?.numberOfUnits || 0
          comparison = unitsB - unitsA
        } else if (state.sortBy === 'amount') {
          const amountA = a?.orderAmount || 0
          const amountB = b?.orderAmount || 0
          comparison = amountB - amountA
        }

        return state.sortDirection === 'asc' ? -comparison : comparison
      })
    }

    setState((prevState: any) => ({ ...prevState, filteredData: filtered }))
  }

  const handleSortTableHead = (property: string) => {
    setState((prevState: any) => {
      const newSortDirection =
        property === prevState.sortBy
          ? prevState.sortDirection === SortDirection.asc
            ? SortDirection.desc
            : SortDirection.asc
          : prevState.sortDirection
      return { ...prevState, sortBy: property, sortDirection: newSortDirection }
    })
  }
  return (
    <div className="table-responsive">
      <table
        className="table table-bordered text-nowrap text-center table-new-sort-filter"
        width="100%"
        cellSpacing="0"
      >
        <thead>
          <tr>
            <th>S.No.</th>
            <th>Name</th>
            <th>Email</th>
            <th>
              <span className="d-block">Unit Type</span>

              <select
                className="w-100"
                value={state.unitType}
                onChange={(e) =>
                  setState({
                    ...state,
                    unitType: e.target.value,
                  })
                }
              >
                <option value="">All</option>
                {Object.values(UnitType).map((unit) => (
                  <option key={unit} value={unit}>
                    {UnitDisplayNames[unit]}
                  </option>
                ))}
              </select>
            </th>
            <SortableTableHeader
              label="No. of Units"
              property="units"
              sortBy={state.sortBy}
              sortDirection={state.sortDirection}
              handleSortTableHead={handleSortTableHead}
            />

            <SortableTableHeader
              label="Payment Amount"
              property="amount"
              sortBy={state.sortBy}
              sortDirection={state.sortDirection}
              handleSortTableHead={handleSortTableHead}
            />
            <th>Order Status</th>
            <th>Note(Optional)</th>
            <th>Approve/Dis-Approve</th>
          </tr>
        </thead>
        <tbody>
          {state?.filteredData &&
            state.filteredData.map((user: any, userIndex: number) => (
              <tr key={`${index}-${userIndex}`} className="text-center">
                <td>
                  <b>{userIndex + 1}</b>
                </td>
                <td>{user.user.firstName + ' ' + user.user.lastName}</td>
                <td>{user.user.email}</td>
                <td>{UnitDisplayNames[user.unitType as UnitType]}</td>
                <td>{formatNumberWithCommas(user.numberOfUnits)}</td>
                <td>
                  {formatNumberWithCommasUptoTwoDecimalWithCeil(
                    user.orderAmount / ONE_USD_TO_CENTS,
                  )}{' '}
                  {CurrencyDisplaySymbol[user.currency as CurrencyType]}
                </td>
                <td>
                  {user.orderStatus === OrderStatus.Completed
                    ? OrderStatus.Approved
                    : user.orderStatus}
                </td>
                <td>
                  {user.buy?.comments ? (
                    <HasToolTipLabel
                      labelText={user.buy?.comments?.slice(0, 8) + ' ... '}
                      htmlFor={`tooltip-${user.orderId}`}
                      id={`tooltip-${user.orderId}`}
                      txt={user.buy?.comments}
                    />
                  ) : (
                    ''
                  )}
                </td>
                <td
                  className="text-secondary"
                  onClick={() => {
                    setOrderId(user.orderId)
                    toggle()
                  }}
                >
                  <input
                    type="checkbox"
                    name="group1"
                    data-toggle="modal"
                    data-target="#exampleModalCenter"
                    disabled={
                      user.orderStatus === OrderStatus.Completed ||
                      user.orderStatus === OrderStatus.Approved ||
                      user.orderStatus === OrderStatus.Cancelled
                    }
                    checked={
                      user.orderStatus === OrderStatus.Completed ||
                      user.orderStatus === OrderStatus.Approved ||
                      user.orderStatus === OrderStatus.Cancelled
                    }
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  )
}
