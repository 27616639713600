// @ts-nocheck
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form, FormGroup, Input,  } from "reactstrap";
import * as yup from "yup";
import { FormErrorMessage } from "../../../../FormErrorMessage";
import QButton from "../QButton/QButton";

interface FormData {
    name: string;
    email: string;
    telephone: string;
    message: string;
}

const schema = yup.object().shape({
    name: yup.string().required("Name is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
    telephone: yup.string().required("Telephone is required"),
    message: yup.string().required("Message is required"),
});

const ContactFormDemo: React.FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data: FormData) => {
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
        console.log(data);
        // You can do something with the form data here, like submit it to a server
    };

    const { ref: nameRef, ...registername } = register("name");
    const { ref: emailRef, ...registeremail } = register("email");

    const { ref: telephoneRef, ...registertelephone } = register("telephone");
    const { ref: messageRef, ...registermessage } = register("message");
    return (
        <div className="row justify-content-center">
            <div className="col-xl-5 col-lg-5 col-md-6">
                <div className="box_account regis-form">
                    <h3 className="new_client">Contact Us</h3>
                    <small className="float-end pt-2">* Required Fields</small>
                    <Form className="form_container" onSubmit={handleSubmit(onSubmit)} noValidate>
                        <div className="private box">
                            <p className="fw-bold">
                                For any generall queries please complete all fields in the contact
                                form below
                            </p>
                            <FormGroup>
                                <Input
                                    type="text"
                                    id="name"
                                    placeholder="Name"
                                    innerRef={nameRef}
                                    {...registername}
                                    invalid={!!errors.name}
                                />
                                <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
                            </FormGroup>
                            <FormGroup>
                                <Input
                                    type="email"
                                    id="email"
                                    placeholder="Email"
                                    innerRef={emailRef}
                                    {...registeremail}
                                    invalid={!!errors.email}
                                />
                                <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
                            </FormGroup>
                            <FormGroup>
                                <Input
                                    type="tel"
                                    id="telephone"
                                    placeholder="Telephone"
                                    innerRef={telephoneRef}
                                    {...registertelephone}
                                    invalid={!!errors.telephone}
                                />
                                <FormErrorMessage>{errors.telephone?.message}</FormErrorMessage>
                            </FormGroup>
                            <FormGroup>
                                <Input
                                    type="textarea"
                                    id="message"
                                    placeholder="Message"
                                    rows="6"
                                    cols="50"
                                    innerRef={messageRef}
                                    {...registermessage}
                                    invalid={!!errors.message}
                                />
                                <FormErrorMessage>{errors.message?.message}</FormErrorMessage>
                            </FormGroup>
                        </div>
                        <QButton
                            type="submit"
                            round
                            fullWidth
                            loading={isLoading}
                            // className="btn_1 full-width"
                        >
                            Submit
                        </QButton>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default ContactFormDemo;
