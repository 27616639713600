import { useEffect, useState } from 'react'
import { getUserOrderApprovedOrCompleted } from '../../api'
import { Link } from 'react-router-dom'
//@ts-ignore
import {
  SelectComponentValue,
  TableTopFiltersNewUI,
  TRInfo,
} from '../../component/misc/smallComponents'
import {
  CurrencyDisplaySymbol,
  ONE_USD_TO_CENTS,
  SortDirection,
  SortFieldsEnumForTransactionHistoryQuery,
  Transaction,
  UnitDisplayNames,
  UnitType,
} from '../../utils/types'
import {
  displayPaymentAmount,
  formatNumberWithCommas,
  formatNumberWithCommasUptoTwoDecimalWithCeil,
  getEnteredByName,
} from '../../utils/utilities'
import { toast } from 'react-toastify'
import { SvgIcons } from '../../component/misc/SvgIcons'
import { camelCaseToSpaced } from '../../utils/common'

const BuyAndSellApprovedOrCompleted = () => {
  const [state, setState] = useState<any>({
    sortDirection: SortDirection.desc,
    isExportDownloadLoading: false,
    dataList: [],
    currentPage: 1,
    pageSize: 50,
    searchTerm: '',
    searchType: 'name',
    orderStatus: '',
    orderType: '',
    unitType: '',
    paymentMethod: '',
    userType: '',
    fromDate: '',
    toDate: '',
    sortBy: SortFieldsEnumForTransactionHistoryQuery.CreatedAt,
  })

  const getData = async () => {
    try {
      const {
        currentPage,
        pageSize,
        searchType,
        searchTerm,
        fromDate,
        toDate,
        unitType,
        paymentMethod,
        userType,
        orderType,
        orderStatus,
        sortBy,
        sortDirection,
      } = state
      const res = await getUserOrderApprovedOrCompleted(
        currentPage,
        pageSize,
        searchType,
        searchTerm,
        fromDate,
        toDate,
        unitType,
        paymentMethod,
        userType,
        orderType,
        orderStatus,
        sortBy,
        sortDirection,
      )
      setState((prevState: any) => ({
        ...prevState,
        dataList: res.data.orders,
      }))
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getData()
  }, [
    state.currentPage,
    state.searchTerm,
    state.searchType,
    state.fromDate,
    state.toDate,
    state.paymentMethod,
    state.userType,
    state.unitType,
    state.orderType,
    state.orderStatus,
    state.sortBy,
    state.sortDirection,
  ])

  const resetAllFiltersAndSort = () => {
    setState({
      sortDirection: SortDirection.desc,
      dataList: [],
      searchTerm: '',
      searchType: 'name',
      orderStatus: '',
      orderType: '',
      paymentMethod: '',
      fromDate: '',
      toDate: '',
      sortBy: SortFieldsEnumForTransactionHistoryQuery.CreatedAt,
    })
    getData()
  }

  const handleSortTableHead = (property: string) => {
    setState((prevState: any) => {
      return {
        ...prevState,
        sortBy: property,
      }
    })
  }
  const handleExportOrder = async () => {
    setState((prevState: any) => ({
      ...prevState,
      isExportDownloadLoading: true,
    }))
    try {
      const {
        currentPage,
        pageSize,
        searchType,
        searchTerm,
        fromDate,
        toDate,
        paymentMethod,
        unitType,
        userType,
        orderType,
        orderStatus,
        sortBy,
        sortDirection,
      } = state
      const response = await getUserOrderApprovedOrCompleted(
        currentPage,
        pageSize,
        searchType,
        searchTerm,
        fromDate,
        toDate,
        unitType,
        paymentMethod,
        userType,
        orderType,
        orderStatus,
        sortBy,
        sortDirection,
        true,
      )
      const blob = new Blob([response.data], { type: 'text/csv' })

      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'orders.csv') // Set the file name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link) // Clean up

      setState((prevState: any) => ({
        ...prevState,
        isExportDownloadLoading: false,
      }))
    } catch (error: any) {
      toast.error(error.response?.data?.message || 'Failed to download CSV')
      setState((prevState: any) => ({
        ...prevState,
        isExportDownloadLoading: false,
      }))
    }
  }
  const { sortBy, sortDirection } = state

  return (
    <>
      <div className="card-ui-pages-content-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <ol
                className="breadcrumb"
                style={{ paddingTop: '2rem', paddingBottom: '0.6rem' }}
              >
                <li className="breadcrumb-item">
                  <Link to="/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">
                  Approved/Completed Orders
                </li>
              </ol>
              <div className="">
                <div className="">
                  <TableTopFiltersNewUI
                    state={state}
                    setState={setState}
                    getData={getData}
                    handleExportOrders={handleExportOrder}
                    sortInputs={
                      <>
                        <div className="col-lg-3 mb-3">
                          {/* <Label className="fz14 fw-semibold mb-0">Sort Order</Label> */}
                          <SelectComponentValue
                            value={sortDirection}
                            placeholder="Sort Order"
                            onChange={(sortDirection) =>
                              setState((prev: any) => ({
                                ...prev,
                                sortDirection,
                              }))
                            }
                            options={[
                              { value: SortDirection.asc, label: 'Ascending' },
                              {
                                value: SortDirection.desc,
                                label: 'Descending',
                              },
                            ]}
                          />
                        </div>
                        <div className="col-lg-3 mb-3">
                          {/* <Label className="fz14 fw-semibold mb-0">Sory By</Label> */}
                          <SelectComponentValue
                            value={sortBy}
                            placeholder="Sory By"
                            onChange={(sortBy) => {
                              // @ts-ignore
                              handleSortTableHead(sortBy)
                            }}
                            options={[
                              {
                                label: 'Number of Units',
                                value: 'currencyUnit.numberOfUnits',
                              },
                              {
                                label: 'Value',
                                value: 'paymentDetails.paymentAmount',
                              },
                              { label: 'Date', value: 'createdAt' },
                            ]}
                          />
                        </div>
                      </>
                    }
                  />

                  <div className="tr-card-list">
                    {state?.dataList &&
                      state.dataList.map((item: Transaction, index: number) => (
                        <div className="tr-card">
                          <div className="info-icon-wrap">
                            <div className="ic-label-wrap">
                              <div className="ic-label withbg">
                                {SvgIcons.MoneyCash}
                                <span>
                                  {camelCaseToSpaced(item.orderStatus)}
                                </span>
                              </div>
                            </div>
                            <div className="ic-label-wrap">
                              <div className="ic-label withbg">
                                {SvgIcons.CheckCircle}
                                <span>
                                  {
                                    UnitDisplayNames[
                                      item.currencyUnit?.unitType as UnitType
                                    ]
                                  }
                                </span>
                              </div>
                            </div>
                            <div className="ic-label-wrap">
                              <div
                                className={
                                  item.orderType !== 'BUY'
                                    ? 'ic-label up'
                                    : 'ic-label'
                                }
                              >
                                {SvgIcons.ArrowDown}
                                <span>{item.orderType}</span>
                              </div>
                            </div>
                            <div className="ic-label-wrap">
                              <TRInfo
                                value={formatNumberWithCommas(
                                  item.currencyUnit.numberOfUnits,
                                )}
                                label={'Number of Units'}
                              />
                            </div>
                            <div className="ic-label-wrap">
                              <TRInfo
                                value={
                                  <>
                                    {displayPaymentAmount(item) + ' '}
                                    {
                                      CurrencyDisplaySymbol[
                                        item.paymentDetails.currency
                                      ]
                                    }
                                  </>
                                }
                                label={'Value'}
                              />
                            </div>
                          </div>
                          <div className="info-text">
                            <div className="info-text-col-wrap">
                              <div className="info-col">
                                <div className="tr-info-wrap">
                                  <TRInfo
                                    value={
                                      item.user?.firstName +
                                      ' ' +
                                      item.user?.lastName
                                    }
                                    label={'Name'}
                                  />
                                </div>
                                <div className="tr-info-wrap">
                                  <TRInfo
                                    value={
                                      item &&
                                      new Date(
                                        item.createdAt,
                                      ).toLocaleDateString()
                                    }
                                    label={'Created Date'}
                                  />
                                </div>
                              </div>
                              <div className="info-col">
                                <div className="tr-info-wrap">
                                  <TRInfo
                                    value={item.user?.email}
                                    label={'Email'}
                                  />
                                </div>
                                <div className="tr-info-wrap">
                                  <TRInfo
                                    value={getEnteredByName(item.approvedBy)}
                                    label={'Approved by'}
                                  />
                                </div>
                              </div>
                              <div className="info-col">
                                <div className="tr-info-wrap">
                                  <TRInfo
                                    value={
                                      item.paymentDetails?.paymentMethod
                                        ? item.paymentDetails?.paymentMethod
                                        : '--'
                                    }
                                    label={'Payment Method'}
                                  />
                                </div>
                              </div>
                              <div className="info-col">
                                <div className="tr-info-wrap">
                                  <TRInfo
                                    value={
                                      item.buy?.paymentId
                                        ? item.buy.paymentId
                                        : '--'
                                    }
                                    label={'Payment Reference'}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BuyAndSellApprovedOrCompleted
